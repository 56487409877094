import React from 'react'
import { TableLayoutStyle } from './TableLayoutStyle';

const TableLayout = (props) => {
    const {getTableProps, headerGroups, getTableBodyProps, retrieveData, page, prepareRow} = props;
  return (
    <TableLayoutStyle {...getTableProps()}>
        <thead>
            {headerGroups.map((headerGroup, index) => (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                    <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        
                        <span>
                            {column.isSorted
                            ? column.isSortedDesc
                                ? <i className='menu-icon bx bx-sort'></i>
                                : <i className='menu-icon bx bx-sort-alt-2'></i>
                            : ''}
                        </span>
                    </th>
                ))}
                </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()} >
            {
                !retrieveData?.length < 1 && (
                    page?.map((row, index) => {
                        prepareRow(row)
                        return (
                        <tr key={index} {...row.getRowProps()}>
                            {row.cells.map((cell, index) => {
                            return <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                        )
                    })
                    
                    
                )
            }
            
            </tbody>                         
        </TableLayoutStyle>
  )
}

export default TableLayout