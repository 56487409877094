import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MacBook } from '../../../assets/images'
import Button from '../Button'
import { CustomErrorpageStyles } from './CustomErrorpageStyles'

const CustomErrorpage = ({title_text, main_title_text, message_text, has_button, previous_state}) => {
    const navigate = useNavigate();
  return (
    <CustomErrorpageStyles>
        <div className='section_container'>
            <div className='aside_text_content'>
                <p className='title_text'>{title_text}</p>
                <h1 className='main_title_text'>{main_title_text}</h1>
                <p className='message_text'>{message_text}</p>
                
                {
                    has_button && (
                        <div className='btn_container'>
                            <div className='btn_goback'>
                                <Button has_blackborder type={"button"} handleClick={() => !previous_state ? navigate('/login') : window.history.back()}>
                                    &#8592; Go back
                                </Button>
                            </div>
                            <div className='btn_contact'>
                                <Button
                                    bg_blue={true}
                                    handleClick={() => {}}
                                >
                                    Contact Support
                                </Button>
                            </div>
                        </div>
                    )
                }
                
            </div>
            <div className='error_aside_image_container'>
                <div className='image_container'>
                    <img src={MacBook} alt="macBook_image" />
                </div>
                
            </div>
        </div>
    </CustomErrorpageStyles>
  )
}

export default CustomErrorpage