import styled from 'styled-components';

export const CustomCheckboxStyles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    margin-right: 6px;
    cursor: pointer;
  }

  label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 45px;
    color: #344054;
  }
`;
