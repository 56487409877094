import React, { Fragment } from 'react';
import { BorderColorBlueStyles, BorderColorDefaultStyles, BorderNoColorStyles } from './BorderColorStyles';

const BorderColorCard = ({ colorBlue, colorDefault, title, content, nocolor, imageId, imageFile, handleClick }) => {
  return (
    <Fragment>
      {colorBlue && (
        <BorderColorBlueStyles onClick={() => handleClick({ imageId, imageFile })}>
          <h6>{title}</h6>
          <p>{content}</p>
        </BorderColorBlueStyles>
      )}
      {colorDefault && (
        <BorderColorDefaultStyles onClick={() => handleClick({ imageId, imageFile })}>
          <h6>{title}</h6>
          <p>{content}</p>
        </BorderColorDefaultStyles>
      )}
      {nocolor && (
        <BorderNoColorStyles>
          <h6>{title}</h6>
          <p>{content}</p>
        </BorderNoColorStyles>
      )}
    </Fragment>
  );
};

export default BorderColorCard;
