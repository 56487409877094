import styled from 'styled-components';

export const BannerStyles = styled.article`
  display: flex;
  height: 49.5vmax;
  padding-top: 5rem;
  padding: 3rem 0 0 3rem;
  align-items: center;
  overflow: hidden;

  .banner__content {
    width: 50%;
    color: ${(props) => props.theme.colors.basic_white};

    .banner__title {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 60px;
      line-height: 72px;
      letter-spacing: -0.02em;
      margin: 0 0 15px 0;
    }

    .banner__text {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      width: 80%;
      margin: 2.5rem 0;
    }

    .banner__footer_btn {
      margin-top: 50px;
      width: 153px;
    }
  }

  .banner__content_img {
    box-sizing: border-box;
    width: 50%;
    overflow: hidden;
    background: transparent !important;
  }

  .bg_md {
    display: none;
  }

  @media (min-width: 1281px) {
    padding: 3rem 0 0 6rem;
  }

  @media screen and (max-width: 912px) {
    padding: 3rem 2rem;
    flex-direction: column;
    height: 548px;
    overflow: none;
    position: relative;

    .banner__content > .banner__title {
      font-size: 55px;
    }

    .banner__content {
      width: 100%;
      position: absolute;
      left: 2rem;
    }

    .bg_md {
      display: block;
      margin-right: -48%;
    }

    .banner__content_img {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .banner__content > .banner__title {
      font-size: 55px;
    }
  }

  @media screen and (max-width: 550px) {
    height: 678px;

    .banner__content {
      left: 1rem;
    }

    .bg_md {
      margin-top: 117px;
    }

    .banner__content > .banner__title {
      font-size: 50px;
    }
  }

  @media (max-width: 320px) {
    .banner__content > .banner__title {
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em;
    }
  }
`;
