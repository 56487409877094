import { partnership } from '../../../assets/images';
import { gotoRoute } from '../../../utils';
import { BorderColorCard, Button, SectionCard, Title } from '../../shared';
import { PartnershipStyles } from './PartnershipStyles';

const Partnership = () => {
  return (
    <PartnershipStyles id="#partnerships">
      <SectionCard image={partnership}>
        <Title sm text={'Partnership'} />

        <div className="meta" />

        <div className="p_text">
          <Title lg text={`Choose a Partnership Model That Suits You`} />
        </div>

        <hr className="section_line" />

        <section>
          {[
            {
              title: 'API Integration',
              content: `Partner with Rayda to streamline ordering and managing work tools through our API. Rayda offers equipment support, maintenance, and collection, integrating seamlessly with your platform for a smooth end-to-end experience.`,
              nocolor: true
            },
            {
              title: 'Referral Partner',
              content: `Introduce global organizations to Rayda Remote and earn commission. Streamline new hire equipment process, join our partner community and earn from every referral.`,
              nocolor: true
            }
          ].map(({ title, content, nocolor }, index) => (
            <BorderColorCard key={index} title={title} content={content} nocolor={nocolor} />
          ))}
        </section>

        <div className="content_btn">
          <Button
            bg_blue={true}
            handleClick={() => gotoRoute('https://calendly.com/abisoye-fadare/partner-onboarding-brief')}
          >
            Partner With Us
          </Button>
        </div>
      </SectionCard>
    </PartnershipStyles>
  );
};

export default Partnership;
