import styled from 'styled-components';

const SubTitleStyles = styled.h1`
  font-weight: 500;
  font-size: 35px;
  line-height: 41px;
  letter-spacing: -0.02em;
  font-style: normal;

  @media screen and (max-width: 912px) {
    font-size: 23px;
  }

  @media screen and (max-width: 550px) {
    line-height: 32px;
  }
`;

const SubTitleStylesWhite = styled.h1`
  font-weight: 500;
  font-size: 35px;
  line-height: 41px;
  letter-spacing: -0.02em;
  font-style: normal;
  color: ${(props) => props.theme.colors.basic_white};

  @media screen and (max-width: 912px) {
    font-size: 23px;
  }
`;

export { SubTitleStyles, SubTitleStylesWhite };
