const headerData = [
  {
    id: 1,
    name: 'Onboarding',
    path: '#onboarding'
  },
  {
    id: 2,
    name: 'Off-boarding',
    path: '#off-boarding'
  },
  {
    id: 3,
    name: 'Partnerships',
    path: '#partnerships'
  },
  {
    id: 4,
    name: 'FAQs',
    path: '#faqs'
  },
  {
    id: 5,
    name: 'Contact Us',
    path: '#contact-us'
  }
];

const portalHeaderData = [
  {
    id: 1,
    name: 'Overview',
    path: '/portal/overview'
  },
  {
    id: 2,
    name: 'Marketplace',
    path: '/portal/marketplace'
  },
  {
    id: 3,
    name: 'Asset Vault',
    path: '/portal/asset-vault'
  },
  {
    id: 4,
    name: 'Orders',
    path: '/portal/orders'
  },
  {
    id: 5,
    name: 'Employees',
    path: '/portal/employees'
  },
  {
    id: 6,
    name: 'Users',
    path: '/portal/users'
  }
];

export { headerData, portalHeaderData };
