import React, { useContext, useEffect, useRef, useState } from 'react';
import { HiMenu } from 'react-icons/hi';
import { HeaderStyle } from './headerStyles';
import { headerData, portalHeaderData } from './headerData';
import { motion } from 'framer-motion';
import Logo from '../Logo/Logo';
import { MobileNav } from '../../constant';
import { AppContext, gotoRoute } from '../../../utils';
import Button from '../Button';
import { Link } from 'react-scroll';
import { NavLink, useNavigate } from 'react-router-dom';
import { FiLogOut, FiShoppingCart } from 'react-icons/fi';
import InitialsAvatar from '../InitialsAvatar/InitialsAvatar';
import { getUserData } from '../../../services/helper';
import { Badge, IconButton } from '@mui/material';
import SliceText from '../../../utils/helpers/SliceText';
import { removeOffboardingData } from '../../../stateManagement/redux/slice/offboardingSlice';
import { useDispatch } from 'react-redux';

const Header = ({ className, portal }) => {
  // const {pathname} = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    setOpenNav,
    openNav,
    setShow,
    setOpenCart,
    // setOpenNotification,
    counter
  } = useContext(AppContext);
  // const { userData?.first_name, userD ata?.last_name } = JSON.parse(getUserData());

  const userData = JSON.parse(getUserData());
  const [initials, setInitials] = useState('');
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const toggleContainer = useRef(null);
  const toggleContainerMobile = useRef(null);

  useEffect(() => {
    if (userData?.first_name && userData?.last_name) {
      setInitials(`${userData?.first_name[0]} ${userData?.last_name[0]}`);
    }
  }, [userData]);

  const handleOpen = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setShow((prev) => !prev);
  };

  useEffect(() => {
    window.addEventListener('click', onClickOutSideHandle);

    return () => {
      window.removeEventListener('click', onClickOutSideHandle);
    };
  }, [showProfileDropdown]);

  const onClickOutSideHandle = (e) => {
    if (toggleContainer.current && toggleContainer.current.contains(e.target)) {
      return setShowProfileDropdown((currentState) => !currentState);
    }
    if (toggleContainerMobile.current && toggleContainerMobile.current.contains(e.target)) {
      return setShowProfileDropdown((currentState) => !currentState);
    }
    return setShowProfileDropdown(false);
  };

  return (
    <header className={className}>
      <HeaderStyle>
        <nav className="app__navbar">
          <section className="nav_section">
            <motion.div
              whileInView={{ y: [20, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__navbar-logo"
            >
              {!portal ? <Logo /> : <Logo footer />}
            </motion.div>
            <ul className="app_navbar-link">
              {headerData &&
                !portal &&
                headerData.map(({ id, name, path }, index) => (
                  <li onClick={() => dispatch(removeOffboardingData())} key={id}>
                    <Link
                      className="nav_link"
                      activeClass="activeClassName"
                      to={path}
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      key={index}
                    >
                      {name}
                    </Link>
                  </li>
                ))}
              {portalHeaderData &&
                portal &&
                portalHeaderData.map(({ id, name, path }) => (
                  <li onClick={() => dispatch(removeOffboardingData())} key={id}>
                    <NavLink className="nav_link_portal" to={path}>
                      {name}
                    </NavLink>
                  </li>
                ))}
            </ul>
          </section>

          {!portal && (
            <div className="nav_btn_container">
              {/* <Button withBorderTransparentColor={true} handleClick={() => navigate('/')}>
                Login
              </Button> */}
              {/* <div className="meta" /> */}
              <Button
                bg_default={true}
                handleClick={() => gotoRoute('https://calendly.com/abisoye-fadare/partner-onboarding-brief')}
              >
                Get started
              </Button>
            </div>
          )}

          {portal && (
            <div className="nav_btn_container_portal">
              <div>{/* <FiSettings /> */}</div>
              {/* <div>
                <IconButton onClick={() => {
                  return null
                  // setOpenNotification((prev) => !prev)
                }} aria-label="notification">
                  <FiBell />
                </IconButton>
              </div> */}
              <div>
                <IconButton onClick={() => setOpenCart((prev) => !prev)} aria-label="cart">
                  <Badge badgeContent={Number(counter)} color="primary">
                    <FiShoppingCart />{' '}
                  </Badge>
                </IconButton>
              </div>
              <div>
                <InitialsAvatar ref={toggleContainer} initials={initials} />
              </div>
            </div>
          )}

          {/* {
                    
                pathname !== "/" ? (
                    <motion.div
                    whileInView={{ x: [100, 0] }}
                    transition={{ duration: 0.85, opacity: [0, 1], ease: 'easeOut' }} 
                    className="nav_btn_container">
                        <Button withBorderTransparentColor={true} handleClick={handleOpen}>Login</Button>
                        <div className='meta'/>
                        <Button bg_default={true} handleClick={handleOpen}>Sign Up</Button>
                        
                    </motion.div>
                    
                    
                ) : (
                    <div className="nav_btn_container">
                        <Button withBorderTransparentColor={true} handleClick={() => gotoRoute("https://portal.rayda.co/login")} >Login</Button>
                        <div className='meta'/>
                        <Button bg_default={true} handleClick={() => gotoRoute("https://portal.rayda.co/register")}>Sign Up</Button>
                        
                    </div>
                )
            } */}
        </nav>

        <nav className="app__navbar_mobile">
          {!portal ? <Logo /> : <Logo footer />}

          {portal && (
            <>
              <div className="mobile_nav_btn_container_portal">
                <div>{/* <FiSettings /> */}</div>
                {/* <div>
                  <IconButton onClick={() => {
                    return null
                    // setOpenNotification((prev) => !prev)
                  }}
                    aria-label="notification">
                    <FiBell />
                  </IconButton>
                </div> */}

                <div>
                  <IconButton onClick={() => setOpenCart((prev) => !prev)} aria-label="cart">
                    <Badge badgeContent={Number(counter)} color="primary">
                      <FiShoppingCart />{' '}
                    </Badge>
                  </IconButton>
                </div>
                <InitialsAvatar ref={toggleContainerMobile} initials={initials} />
              </div>

              <div className="xs_mobile_nav_btn_container_portal">
                {/* <div>
                  <IconButton onClick={() => {
                    return null
                    // setOpenNotification((prev) => !prev)
                  }} aria-label="notification">
                    <FiBell />
                  </IconButton>
                </div> */}

                <div>
                  <IconButton onClick={() => setOpenCart((prev) => !prev)} aria-label="cart">
                    <Badge badgeContent={Number(counter)} color="primary">
                      <FiShoppingCart />{' '}
                    </Badge>
                  </IconButton>
                </div>
              </div>
            </>
          )}

          {!openNav && <HiMenu size={35} onClick={() => setOpenNav((prev) => !prev)} />}

          {!portal && openNav && <MobileNav headerData={headerData} handleOpen={handleOpen} open={openNav} />}
          {portal && openNav && (
            <MobileNav
              initials={initials}
              portal
              headerData={portalHeaderData}
              handleOpen={handleOpen}
              open={openNav}
            />
          )}
        </nav>

        {showProfileDropdown ? (
          <div className="profile-dropdown">
            <div className="profile-dropdown__user-info">
              <InitialsAvatar initials={initials} />
              <div className="profile-dropdown__user-info--email">
                {userData &&
                  userData?.first_name !== null &&
                  userData?.first_name !== undefined &&
                  userData?.last_name !== null &&
                  userData?.last_name !== undefined && <span>{userData?.first_name + ' ' + userData?.last_name}</span>}
                {userData?.email?.length > 20 ? (
                  <span>
                    <SliceText number={18} text={userData?.email} />
                  </span>
                ) : (
                  <span>{userData?.email}</span>
                )}
              </div>
            </div>

            <div className="profile-dropdown__actions">
              {/* <span>
              <FiUser />
              View Profile
            </span> */}
              <span
                onClick={() => {
                  localStorage.clear();
                  navigate('/login');
                }}
              >
                <FiLogOut />
                Log out
              </span>
            </div>
          </div>
        ) : null}
      </HeaderStyle>
    </header>
  );
};

export default Header;
