import React, { Fragment, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { AppContext } from '../../utils';
import 'react-toastify/dist/ReactToastify.css';
import Navigator from '../../navigator/Navigator';
import { CountryModal } from '../../components/constant';
import CartModal from '../../components/shared/CartModal/Cart';
import Notification from '../../components/shared/Notification/notification';

const Layout = () => {
  const [show, setShow] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openEditempoyee, setOpenEditEmployee] = useState(false);
  const [openEditShippingDetails, setOpenEditShippingDetails] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [counter, setCounter] = useState(0);
  const [activeOnboardingStep, setActiveOnboardingStep] = useState(0);
  const [ openCreateAsset, setOpenCreateAsset ] =  useState(false);

  return (
    <AppContext.Provider
      value={{
        show,
        setShow,
        openNav,
        setOpenNav,
        openCart,
        setOpenCart,
        counter,
        setCounter,
        openEditempoyee,
        setOpenEditEmployee,
        openEditShippingDetails,
        setOpenEditShippingDetails,
        openEditUser,
        setOpenEditUser,
        openNotification,
        setOpenNotification,
        activeOnboardingStep,
        setActiveOnboardingStep,
        openCreateAsset,
        setOpenCreateAsset
      }}
    >
      <Fragment>
        <div>
          <ToastContainer />
          <Navigator />
          <CountryModal />
          <CartModal />
          <Notification />
        </div>
      </Fragment>
    </AppContext.Provider>
  );
};

export default Layout;
