import styled from 'styled-components';

export const CartItemCardStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  height: 76px;
  border-bottom: 1px solid #eaecf0;
  width: 100%;
  margin-top: 12px;
  padding-bottom: 12px;

  .image_area {
    width: 64px;
  }

  .header_content {
    flex: 1;
    padding-left: 1rem;

    h1 {
      font-weight: 600;
      font-size: 17px;
      line-height: 28px;
    }

    .meta {
      margin: 6px 0;
    }
  }

  .header_action_area {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    svg {
      font-size: 20px;
      color: #667085;
      cursor: pointer;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #475467;
    }

    .meta {
      margin: 6px 0;
    }
  }

  .image_container {
    width: 100%;

    img {
      width: 64px;
      height: 64px;
    }
  }

  @media (min-width: 1281px) {
    .header_content {
      h1 {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 481px) {
    .header_content {
      h1 {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 414px) {
    .header_content {
      h1 {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 360px) {
    .header_content {
      padding-left: 0.6rem;
    }

    .header_action_area {
      width: 110px;

      p {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 280px) {
    .image_area {
      width: 40px;
    }

    .image_container {
      img {
        width: 40px;
        height: 40px;
      }
    }

    .header_content {
      h1 {
        font-size: 10px;
      }
    }

    .header_action_area {
      width: 90px;

      svg {
        font-size: 14px;
      }

      p {
        font-size: 12px;
      }
    }
  }
`;
