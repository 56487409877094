import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${(props) => props.theme.fonts.join`,`};
    scroll-behavior: smooth;

    ::-webkit-scrollbar-thumb { 
      background: #EAECF0;
      border-radius: 8px;
    }

    ::-webkit-scrollbar {
      width: 8px; 
      height: 100%; 
      background: #FFFFFF;
      border-radius: 8px;
    }
  }`;



export default GlobalStyle;
