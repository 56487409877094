import React from 'react';
import { asset_vault, offboarding_image, quick_it, secure_asset_data } from '../../../assets/images';
import { TitlespacedContent } from '../../shared';
import { OffBoardingStyles } from './OffBoardingStyles';

const OffBoarding = () => {
  return (
    <OffBoardingStyles id="#off-boarding">
      <TitlespacedContent
        reverse
        title_sm={'Off-boarding'}
        title_lg={'Stress-Free Offboarding'}
        description={`Asset Pickup, Data Wipe & Hold or Sell Services - We'll help you hold assets for future use or sell them off for cash value.`}
        data={[
          {
            title: 'Quick IT Asset Pickup or Delivery',
            content: `Get hassle-free asset pickup or delivery service from us. We handle all logistics and ensure that exit documentation is properly taken care of for a smooth transition.`,
            imageFile: quick_it,
            imageId: '011'
          },
          {
            title: 'Secure Asset Data Wipe',
            content: `We recognize the importance of data protection and privacy. That's why we offer a professional service to wipe data from devices, ensuring that your sensitive information remains secure and private.`,
            imageFile: secure_asset_data,
            imageId: '012'
          },
          {
            title: 'Asset Vault',
            content: `When it comes to your organization's assets, you have the option to hold onto them in the case of future redeployment or sell them off to get a cash value back to the organization.`,
            imageFile: asset_vault,
            imageId: '013'
          }
        ]}
        image={offboarding_image}
      />
    </OffBoardingStyles>
  );
};

export default OffBoarding;
