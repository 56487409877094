import styled from 'styled-components';

export const GridStyles = styled.article`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.25rem;

  @media screen and (min-width: 1281px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }

  @media screen and (max-width: 414px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const GridStretchStyles = styled.article`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(277.75px, 1fr));
  row-gap: 2rem;
  column-gap: 2rem;
  margin: 25px 0;

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }

  @media screen and (max-width: 414px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const GridLgStyles = styled.article`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.25rem;

  @media screen and (min-width: 1281px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 912px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 414px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
