import styled from 'styled-components';

export const EmptyInstanceStyle = styled.div`
  text-align: center;
  margin-top: 52px;

  .empty_instance_img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 160px;
    margin: 12px auto;
  }

  .no_asset_h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    width: 352px;
    height: 30px;
    margin: 12px auto;
  }

  .no_asset_p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    width: 352px;
    height: 48px;
    margin: auto;
  }

  .empty_instance_btn {
    width: 17.56rem;
    height: 44px;
    margin: 1rem auto;
    display: flex;

    & a{
      width: 100%;
      text-decoration: none;    
    }
    
    & :first-child{
      margin-right: 1rem;
    }
  }
`;
