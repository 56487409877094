import Loadable from 'react-loadable';
import { CustomLoader } from '../components/shared';

const home = import('./home/index');
const marketPlace = import('./home/MarketPlace/Mainstore');
const signUp = import('./auth/SignUp/SignUp');
const registerInvitedUser = import('./auth/RegisterInvitedUser/RegisterInvitedUser');
const verify = import('./auth/Verify/Verify');
const login = import('./auth/Login/Login');
const onboarding = import('./Onboading/Onboarding');
const onboardingFlow = import('./OnboardingFlow/OnboardingFlow');
const onboardingFlowIndex = import('./OnboardingFlow/OnboardingFlowIndex/OnboardingFlowIndex');
const onboardingChooseUser = import('./OnboardingFlow/ChooseUser/ChooseUser');
const profileDetails = import('./Onboading/ProfileDetails/ProfileDetails');
const companyDetails = import('./Onboading/CompanyDetails/CompanyDetails');
const choosePassword = import('./Onboading/ChoosePassword/ChoosePassword');
const chooseUser = import('./Onboading/ChooseUser/ChooseUser');
const forgetPassword = import('./auth/ForgetPassword/ForgetPassword');
const checkEmail = import('./auth/CheckEmail/CheckEmail');
const setNewPassword = import('./auth/SetNewPassword/SetNewPassword');
const successPassword = import('./auth/SuccessPassword/SuccessPassword');
const portal = import('./portal/Portal');
const overview = import('./portal/Overview/Overview');
const dashboard = import('./portal/Overview/Dashboard/Dashboard');
const checkout = import('./portal/Overview/Checkout/Checkout');
const selectEmployee = import('./portal/Overview/Checkout/SelectEmployee/SelectEmployee');
const createEmployee = import('./portal/Overview/Checkout/CreateEmployee/CreateEmployee');
const offBoarding = import('./Offboarding/OffBoarding');
const offBoardingEmployee = import('./Offboarding/Employees/SelectEmployees/Employee');
const offBoardingCreateEmployee = import('./Offboarding/Employees/CreateEmployees/CreateEmployee')
const offBoardingAsset = import('./Offboarding/Assets/ViewAssets/ViewAssets')

// Update Later
const offBoardingPickup = import("./Offboarding/Assets/PickupAssets")

const marketplaceHome = import('./home/MarketPlace/MarketPlaceIndex');
const marketplace = import('./portal/MarketPlace/MarketPlace');
const mainstore = import('./portal/MarketPlace/MainStore');
const marketplace_order = import('./portal/MarketPlace/Order/Order');
const selectedItemDetails = import('./portal/MarketPlace/Details');
const assetVault = import('./portal/AssetVault/AssetVault');
const assetVaultIndex = import('./portal/AssetVault/AssetVaultIndex');
const orders = import('./portal/Orders/Orders');
const orderHistory = import('./portal/Orders/OrderHistory');
const orderDetails = import('./portal/Orders/OrderDetails');
const employees = import('./portal/Employees/Employees');
const employeesIndex = import('./portal/Employees/Index/EmployeesIndex');
const employeesDetails = import('./portal/Employees/EmployeesDetails/EmployeesDetails');
const users = import('./portal/Users/Users');
const deliveryMethod = import('./portal/Overview/Checkout/DeliveryMethod/DeliveryMethod');
const error401 = import('./ErrorPages/Error401/error401');

export const Homepage = Loadable({
  loader: () => home,
  loading: CustomLoader
});

export const HoeMarketPlace = Loadable({
  loader: () => marketPlace,
  loading: CustomLoader
});

export const SignUpPage = Loadable({
  loader: () => signUp,
  loading: CustomLoader
});

export const RegisterInvitedUserPage = Loadable({
  loader: () => registerInvitedUser,
  loading: CustomLoader
});

export const VerifyPage = Loadable({
  loader: () => verify,
  loading: CustomLoader
});

export const LoginPage = Loadable({
  loader: () => login,
  loading: CustomLoader
});

export const OnboardingPage = Loadable({
  loader: () => onboarding,
  loading: CustomLoader
});

export const OnboardingFlowPage = Loadable({
  loader: () => onboardingFlow,
  loading: CustomLoader
});

export const OnboardingFlowIndexPage = Loadable({
  loader: () => onboardingFlowIndex,
  loading: CustomLoader
});

export const OnboardingChooseUserPage = Loadable({
  loader: () => onboardingChooseUser,
  loading: CustomLoader
});

export const ProfileDetailsPage = Loadable({
  loader: () => profileDetails,
  loading: CustomLoader
});

export const CompanyDetailsPage = Loadable({
  loader: () => companyDetails,
  loading: CustomLoader
});

export const ChoosePasswordPage = Loadable({
  loader: () => choosePassword,
  loading: CustomLoader
});

export const ChooseUserPage = Loadable({
  loader: () => chooseUser,
  loading: CustomLoader
});

export const ForgetPasswordPage = Loadable({
  loader: () => forgetPassword,
  loading: CustomLoader
});

export const CheckEmailPage = Loadable({
  loader: () => checkEmail,
  loading: CustomLoader
});

export const SetNewPasswordPage = Loadable({
  loader: () => setNewPassword,
  loading: CustomLoader
});

export const SuccessPasswordPage = Loadable({
  loader: () => successPassword,
  loading: CustomLoader
});

export const PortalPage = Loadable({
  loader: () => portal,
  loading: CustomLoader
});

export const OverviewPage = Loadable({
  loader: () => overview,
  loading: CustomLoader
});

export const DashboardPage = Loadable({
  loader: () => dashboard,
  loading: CustomLoader
});

export const OffBoardingPage = Loadable({
  loader: () => offBoarding,
  loading: CustomLoader
});

export const OffBoardingEmployee = Loadable({
  loader: () => offBoardingEmployee,
  loading: CustomLoader
});

export const OffBoardingCreateEmployee = Loadable({
  loader: () => offBoardingCreateEmployee,
  loading: CustomLoader
});

export const OffBoardingAssets = Loadable({
  loader: () => offBoardingAsset,
  loading: CustomLoader
});

export const PickupPage = Loadable({
  loader: () => offBoardingPickup,
  loading: CustomLoader
});

export const CheckoutPage = Loadable({
  loader: () => checkout,
  loading: CustomLoader
});

export const SelectEmployeePage = Loadable({
  loader: () => selectEmployee,
  loading: CustomLoader
});

export const CreateEmployeePage = Loadable({
  loader: () => createEmployee,
  loading: CustomLoader
});

export const MarketplaceHomePage = Loadable({
  loader: () => marketplaceHome,
  loading: CustomLoader
});

export const MarketplacePage = Loadable({
  loader: () => marketplace,
  loading: CustomLoader
});

export const MainstorePage = Loadable({
  loader: () => mainstore,
  loading: CustomLoader
});

export const MarketplaceOrderPage = Loadable({
  loader: () => marketplace_order,
  loading: CustomLoader
});

export const SelectedItemDetailsPage = Loadable({
  loader: () => selectedItemDetails,
  loading: CustomLoader
});

export const AssetVaultPage = Loadable({
  loader: () => assetVault,
  loading: CustomLoader
});

export const AssetVaultIndex = Loadable({
  loader: () => assetVaultIndex,
  loading: CustomLoader
});

export const OrdersPage = Loadable({
  loader: () => orders,
  loading: CustomLoader
});

export const OrdersHistory = Loadable({
  loader: () => orderHistory,
  loading: CustomLoader
});

export const OrdersDetails = Loadable({
  loader: () => orderDetails,
  loading: CustomLoader
});

export const EmployeesIndexPage = Loadable({
  loader: () => employeesIndex,
  loading: CustomLoader
});

export const EmployeesPage = Loadable({
  loader: () => employees,
  loading: CustomLoader
});

export const EmployeesDetailsPage = Loadable({
  loader: () => employeesDetails,
  loading: CustomLoader
});

export const UsersPage = Loadable({
  loader: () => users,
  loading: CustomLoader
});

export const DeliveryMethodPage = Loadable({
  loader: () => deliveryMethod,
  loading: CustomLoader
});
export const Error401Page = Loadable({
  loader: () => error401,
  loading: CustomLoader
});
