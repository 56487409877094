import React, { Fragment } from 'react';
import {
  BlueButtonStyle,
  ButtonStyle,
  HablackborderButtonStyle,
  HasNoBorderStyles,
  RequestStyles,
  WithBorderColorStyles,
  WithBorderTransparentColorStyles
} from './buttonStyles';

const Button = ({
  bg_default,
  bg_blue,
  has_blackborder,
  handleClick,
  withBorderColor,
  withBorderTransparentColor,
  requestBtn,
  hasNoBorder,
  children,
  disabled,
  data_cy,
  type
}) => {
  return (
    <Fragment>
      {bg_default && (
        <ButtonStyle
          data-testid="button"
          data-cy={data_cy}
          type={type || 'button'}
          disabled={disabled}
          onClick={handleClick}
        >
          {children}
        </ButtonStyle>
      )}

      {bg_blue && (
        <BlueButtonStyle type={type || 'button'} data-cy={data_cy} disabled={disabled} onClick={handleClick}>
          {children}
        </BlueButtonStyle>
      )}

      {has_blackborder && (
        <HablackborderButtonStyle type={type || 'button'} data-cy={data_cy} disabled={disabled} onClick={handleClick}>
          {children}
        </HablackborderButtonStyle>
      )}
      {withBorderColor && (
        <WithBorderColorStyles type={type || 'button'} data-cy={data_cy} disabled={disabled} onClick={handleClick}>
          {children}
        </WithBorderColorStyles>
      )}
      {withBorderTransparentColor && (
        <WithBorderTransparentColorStyles
          type={type || 'button'}
          data-cy={data_cy}
          disabled={disabled}
          onClick={handleClick}
        >
          {children}
        </WithBorderTransparentColorStyles>
      )}
      {hasNoBorder && (
        <HasNoBorderStyles type={type || 'button'} data-cy={data_cy} disabled={disabled} onClick={handleClick}>
          {children}
        </HasNoBorderStyles>
      )}
      {requestBtn && (
        <RequestStyles type={type || 'button'} data-cy={data_cy} disabled={disabled} onClick={handleClick}>
          {children}
        </RequestStyles>
      )}
    </Fragment>
  );
};

export default Button;
