import React from 'react';
import { RiHome3Line } from 'react-icons/ri';
import { BreadcrumbStyle } from './BreadcrumbStyle';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ data, handleHomeClick }) => {
  return (
    <BreadcrumbStyle>
      <RiHome3Line className='homeicon' onClick={() => handleHomeClick?.()} /> <span>{'>'}</span>
      {data?.map((item, index) => {
        if(typeof item === 'object') {
          return (
            item?.pathLink ? (
              <Link to={item?.pathLink} key={index} className="breadcrumb_item">
                {item?.pathname}
              </Link>
            ) : (
              <div key={index} className="breadcrumb_item">
                {item?.pathname}
              </div>
            )
          )
        } else {
          return (
            <div key={index} className="breadcrumb_item">
              {item}
            </div>
          )
          
        }
      })}
    </BreadcrumbStyle>
  );
};

export default Breadcrumb;
