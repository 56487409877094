import styled from 'styled-components';

export const OrderStatusStyles = styled.div`
  width: 100%;
  margin-top: 32px;

  .status_card {
    width: inherit;
  }

  .waiting,
  .in_transit {
    border-radius: 8px;
    border: 2px solid #004ccc;
  }

  .failed {
    border-radius: 10px;
    border: 2px solid #C81C08;
  }

  .normal {
    border-radius: 8px;
    border: 2px solid #eaecf0;
  }

  .picked_up {
    border: 2px solid #039855;
    border-radius: 8px;
  }

  .failed_header {
    background: #C81C08;
    border-bottom: 1px solid #C81C08;
    border-radius: 8px 8px 0px 0px;
    color: #fff;
  }

  .waiting_header {
    background: #f2f7ff;
    border-bottom: 1px solid #004ccc;
    border-radius: 8px 8px 0px 0px;
    color: #002666;
  }

  .normal_header {
    background: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    border-radius: 8px 8px 0px 0px;
    color: #002666;
  }

  .picked_up_header {
    background: #f6fef9;
    border-bottom: 1px solid #039855;
    border-radius: 8px 8px 0px 0px;
    color: #027a48;
  }

  .in_transit_header {
    background: #f2f7ff;
    border-bottom: 1px solid #004ccc;
    border-radius: 8px 8px 0px 0px;
    color: #002666;
  }

  .status_card > .header {
    padding: 16px 20px 16px 16px;
    height: 64px;
    width: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;

    .image_1 {
      width: 40px;
    }

    p {
      flex: 1;
      margin-left: 12px;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
    }

    .image_2 {
      width: 24px;
    }
  }

  .status_card > .main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    height: 102px;
    width: inherit;
    justify-content: space-between;

    .bodytext_container > h3 {
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: #344054;
    }

    .bodytext_container > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #475467;
    }

    .waiting_badge {
      color: #344054;
      width: 155px;
      height: 24px;
      // background: #f2f4f7;
    }

    .failed_badge {
      color: #ff0000d6;
      width: 155px;
      height: 24px;
      text-align: center;
      // background: #ff0000d6;
    }

    .failed_badge,
    .waiting_badge,
    .in_transit_badge,
    .picked_up_badge {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 10px;
      mix-blend-mode: multiply;
      border-radius: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }

    .picked_up_badge {
      width: 155px;
      height: 24px;
      // background: #ecfdf3;
      color: #027a48;
    }

    .in_transit_badge {
      color: #b54708;
      width: 155px;
      height: 24px;
      // background: #fffaeb;
    }
  }

  @media screen and (max-width: 481px) {
    .status_card > .main {
      flex-direction: column;
    }
  }
`;
