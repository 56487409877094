import { ImageCTStyles } from "./ImageCTStyles"

const ImageContent = ({image, children, imageLoaded}) => {
  return (
    <ImageCTStyles>
      <section className="image_section">
        <div className="image_container">
          <img className="img_layout" src={image} alt={`${image}`} onLoad={imageLoaded}/>
        </div>
      </section>
      <section className='content_container'>
        {children}
      </section>
    </ImageCTStyles>
  )
}

export default ImageContent