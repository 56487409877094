import styled from 'styled-components';

export const OverviewCardStyles = styled.section`
  padding: 24px;
  height: 192px;
  width: 100%;
  ${'' /* width: 367px; */}
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid #eaecf0;

  .overview_p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 15px;
    color: #475467;
  }

  .sub_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    h1 {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      color: #101828;
      letter-spacing: -0.02em;
    }

    .p_tag {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background: #f8f9fc;
      mix-blend-mode: multiply;
      border-radius: 16px;
      width: 106px;
      height: 24px;
      text-align: center;
      padding: 2px 10px;
    }

    .icon_text {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 10px 2px 8px;
      gap: 4px;
      width: 65px;
      height: 24px;
      background: #ecfdf3;
      mix-blend-mode: multiply;
      border-radius: 16px;
    }

    .p_tag_green {
    }
  }

  @media screen and (max-width: 550px) {
    height: auto;
    width: 100%;
  }
`;
