import React from 'react'
import { GridLgStyles, GridStretchStyles, GridStyles } from './GridStyles'

const Grid = ({lg, stretch, children}) => {
  return (
    <>
      {
        lg ? (
          <GridLgStyles>
            {children}
          </GridLgStyles>
        ) : stretch ? (
          <GridStretchStyles>
            {children}
          </GridStretchStyles>
        ) : (
          <GridStyles>
              {children}
          </GridStyles>
        )
      }
    </>
    
  )
}

export default Grid