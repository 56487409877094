import styled from 'styled-components';

export const SubscribeStyles = styled.section`
  height: 404px;
  padding: 6rem 3rem;
  width: 100%;

  .subcribe_container {
    height: 212px;
    width: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 64px;
    border-radius: 16px;
    background: ${(props) => props.theme.colors.primary_1000};
  }

  .text_area {
    width: 60%;
  }
  .action_area {
    width: 40%;
  }

  .action_field {
    width: 68%;
  }

  ${
    '' /* .action_field {

    } */
  }

  .h1_text {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.neutral_10};
  }

  .p_text {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.colors.neutral_10};
    width: 576px;
    margin-top: 20px;
  }

  .action_area_field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .content_btn {
    width: 115px;
  }

  .action_smtext {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.neutral_10};
  }

  @media (min-width: 1281px) {
    padding: 6rem;
  }

  @media (min-width: 1281px) {
    .action_field {
      width: 70%;
    }
  }

  @media screen and (max-width: 912px) {
    padding: 3rem 2rem;
    height: auto;

    .subcribe_container {
      height: auto;
    }

    .subcribe_container,
    .action_area_field {
      flex-direction: column;
    }

    .text_area,
    .action_area,
    .action_field,
    .content_btn,
    .p_text {
      width: 100%;
    }

    .action_area {
      margin-top: 3rem;
    }

    .action_smtext {
      display: block;
      margin-top: 1rem;
    }

    .content_btn {
      margin-top: 2rem;
    }
  }

  @media screen and (max-width: 550px) {
    padding: 3rem 1rem;

    .subcribe_container {
      padding: 3rem;
    }

    .action_smtext {
      margin-top: 0;
    }

    .p_text {
    }
  }

  @media (max-width: 320px) {
    .subcribe_container {
      padding: 3rem 1rem 2rem 1rem;
    }
  }
`;
