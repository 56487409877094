import styled from 'styled-components';

export const TitlespacedContStyles = styled.article`
  ${'' /* margin-top: 64px; */}

  .meta {
    margin-top: 12px;
  }

  .tspc_pText {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    width: 768px;
    color: ${(props) => props.theme.colors.neutral_750};
  }

  .section_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 64px;
  }

  .section_card_reversed {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 64px;
  }

  .section_card > .tspc_article_scetion {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .section_card .tspc_image_scetion {
    width: 43%;
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.colors.primary_50};
  }

  .section_card_reversed > .tspc_article_scetion {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .section_card_reversed .tspc_image_scetion {
    width: 43%;
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.colors.blue};
    height: 430px;
  }

  .tspc_image {
    width: 100%;
    margin: 0 auto;

    img {
      width: 100%;
      object-fit: fill;
    }
  }

  @media screen and (max-width: 912px) {
    .section_card {
      flex-direction: column;
      align-items: flex-start;
    }

    .section_card_reversed {
      flex-direction: column;
    }

    .section_card > .tspc_article_scetion,
    .section_card > .tspc_image_scetion,
    .section_card_reversed > .tspc_article_scetion,
    .section_card_reversed > .tspc_image_scetion {
      width: 100%;
    }

    .section_card > .tspc_image_scetion {
      margin-top: 3rem;
    }
    .section_card_reversed > .tspc_image_scetion {
      margin-top: 4rem;
    }

    .tspc_pText {
      width: 100%;
    }

    .tspc_image {
      width: 100%;

      img {
        width: 100%;
        object-fit: fill;
      }
    }
  }

  @media screen and (max-width: 820px) {
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;

    .tspc_image_scetion,
    .tspc_article_scetion {
      width: 100%;
    }

    .section_card_reversed .tspc_image_scetion {
      height: auto;
    }

    .defaultFile_next {
      margin-top: 50px;
    }
  }
`;
