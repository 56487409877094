import { Navigate, useSearchParams } from 'react-router-dom';
import {
  getToken,
  // getTransactionRef,
  getTransactionStatus,
  setTransactionRef,
  setTransactionStatus
} from '../../services/helper';

export const CustomPortalProtectedRoute = ({ children }) => {
  if (!getToken()) {
    return <Navigate to="/login" />;
  }

  return children;
};

export const ProtectedMarketplaceOrder = ({ children }) => {
  const [searchParams] = useSearchParams();
  const { pathname } = window.location;

  const status = searchParams.get('status');
  const tx_ref = searchParams.get('tx_ref');

  setTransactionStatus(status);
  setTransactionRef(tx_ref);

  // if (getTransactionStatus() === 'successful' || (getTransactionStatus() === 'success' && getTransactionRef()) || !getTransactionStatus()) {
  //   return children;
  // }

  if (getTransactionStatus() === 'failed') {
    return (window.location.href = `/portal/marketplace/main_store?redirect=${pathname}`);
  }

  if (getTransactionStatus() === 'cancelled') {
    return (window.location.href = `/portal/marketplace/main_store?redirect=${pathname}`);
  }

  return children;
};
