import { useState } from 'react';
import Sticky from 'react-stickynode';
import { Header } from '../../shared';
import { MainHeaderStyles } from './mainHeaderStyles';

const MainHeader = ({ normal }) => {
  const [isSticky, setIsSticky] = useState(false);

  const handleStateChange = (status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setIsSticky(true);
    } else if (status.status === Sticky.STATUS_ORIGINAL) {
      setIsSticky(false);
    }
  };
  return (
    <MainHeaderStyles>
      <Sticky enabled={false} innerZ={1001} top={0} onStateChange={handleStateChange}>
        <Header className={`${isSticky ? 'sticky' : `unSticky ${normal && 'normal'}`} `} />
      </Sticky>
    </MainHeaderStyles>
  );
};

export default MainHeader;
