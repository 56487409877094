import styled from 'styled-components';

export const CustomSelectStyle = styled.div`
  .form_label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 26px 0 12px 0;
    display: block;
  }

  .css-v68sna-control {
    height: 48px !important;
  }
`;
