import styled from 'styled-components';

export const CustomTIStyles = styled.div`
  width: 100%;

  .form_label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 26px 0 12px 0;
    display: block;
  }
`;
