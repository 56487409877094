import styled from 'styled-components';

const FooterStyles = styled.footer`
  flex-direction: column;
  align-items: center;
  padding: 6rem 3rem;
  width: 100%;
  height: 320px;

  .footer-col-1 {
    width: 100%;
  }
  .route_and_externalLinks {
    margin-top: 2.5rem;
    width: 100%;
  }

  .route_and_externalLinks,
  .routes,
  .externalLinks,
  .footer-col-2 {
    display: flex;
    flex-direction: row;
  }

  .routes {
    ${'' /* width: 60%; */}
  }

  .externalLinks {
    ${'' /* width: 40%; */}
  }

  .route_and_externalLinks,
  .footer-col-2 {
    justify-content: space-between;
    align-items: center;
  }

  .routes > .nav_link {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    margin-right: 2rem;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.colors.neutral_400};
    }
  }

  .externalLinks > a,
  .data_related > a {
    margin-left: 2rem;
  }

  .copyRight > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }

  ${
    '' /* .externalLinks > a:hover{
        color: #004CCC;
    } */
  }

  .footer-col-2 {
    margin-top: 3rem;
  }

  .data_related > a {
    text-decoration: none;
  }

  .data_related > a > small {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;

    &:hover {
      color: ${(props) => props.theme.colors.neutral_400};
    }
  }

  @media (min-width: 1281px) {
    padding: 6rem;
  }

  @media screen and (max-width: 912px) {
    padding: 4rem 2rem 2rem 2rem;
    height: auto;

    .route_and_externalLinks {
      flex-direction: column;
      align-items: flex-start;
    }

    .externalLinks {
      margin-top: 3rem;
    }

    .externalLinks > a {
      margin: 0 2rem 0 0;
    }

    .footer-col-2 {
      margin-top: 4rem;
    }
  }

  @media screen and (max-width: 550px) {
    padding: 4rem 1rem 2rem 1rem;

    .routes {
      flex-wrap: wrap;
    }

    .routes > .nav_link {
      line-height: 40px;
    }

    .footer-col-2 {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .data_related > a {
      margin: 0 2rem 0 0;
    }

    .copyRight {
      margin-top: 1rem;
    }
  }
`;

export { FooterStyles };
