import {
  requestAddCart,
  requestAllEmployee,
  requestAllOrderHistory,
  requestAllPersonnel,
  requestAvaliableProduct,
  requestAvaliableProductForPublic,
  requestBuy,
  requestClearCart,
  requestCreateAsset,
  requestCreateEmployee,
  requestDashboardData,
  requestDeleteCart,
  requestDeleteEmployee,
  requestDeletePersonnelInvitation,
  requestEditEmployee,
  requestEditPersonnelDetails,
  requestEditShippingDetails,
  requestFetchABanks,
  requestFetchAccountInfo,
  requestFetchAsset,
  requestFetchAssetCategories,
  requestFetchAssetVault,
  requestFetchCart,
  requestFetchOrderAssets,
  requestOffBoardAsset,
  requestOrderBuy,
  requestOrderDetail,
  requestPersonnelInvitation,
  requestPublicFetchAssetCategories,
  requestResendPersonnelInvitation,
  requestSaveAccountInfo,
  requestSaveDeliveryAddress,
  requestShippingOptions,
  requestSingleEmployee,
  requestSingleOrder,
  requestSinglePersonnel,
  requestSingleProduct,
  requestUpdateCart
} from '../../../services/request.js/portal.request';

export const dashboardDataAction = async ({ queryKey }) => {
  try {
    const [_, company_id] = queryKey;
    const data = await requestDashboardData(company_id);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getAllProductDataAction = async ({ queryKey }) => {
  try {
    const [_, company_id] = queryKey;
    const data = await requestAvaliableProduct(company_id);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const avaliableProductAction = async ({ company_id, enableQuery }) => {
  try {
    const data = await requestAvaliableProduct(company_id, enableQuery);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};
// export const avaliableProductAction = async ({ company_id, enableQuery }) => {
//   try {
//     const data = await requestAvaliableProduct(company_id, enableQuery);

//     return data;
//   } catch (error) {
//     throw error.response.data;
//   }
// };

export const avaliableProductForPublicAction = async ({ country, page_no = 1, enableQuery }) => {
  try {
    const data = await requestAvaliableProductForPublic(country, page_no, enableQuery);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const addCartAction = async ({ company_id, formData }) => {
  try {
    const data = await requestAddCart(company_id, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchCartAction = async ({ queryKey }) => {
  try {
    const [_, company_id] = queryKey;
    const data = await requestFetchCart(company_id);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const clearCartAction = async ({ company_id, cart_id, user_id }) => {
  try {
    const data = await requestClearCart(company_id, cart_id, user_id);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchSingleOrderAction = async ({ queryKey }) => {
  try {
    const [_, company_id, transaction_reference] = queryKey;
    const data = await requestSingleOrder(company_id, transaction_reference);
    _;

    return data.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateCartAction = async ({ company_id, cart_id, formData }) => {
  try {
    const data = await requestUpdateCart(company_id, cart_id, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteCartAction = async ({ company_id, cart_id, formData }) => {
  try {
    const data = await requestDeleteCart(company_id, cart_id, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

// export const avaliableProductAction = async ({ queryKey }) => {
//   try {
//     const [_, company_id, enableQuery] = queryKey;
//     const data = await requestAvaliableProduct(company_id, enableQuery);
//     _;
//     return data;
//   } catch (error) {
//     throw error.response.data;
//   }
// };

export const singleProductAction = async ({ queryKey }) => {
  try {
    const [_, company_id, id] = queryKey;
    const data = await requestSingleProduct(company_id, id);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const allEmployeeAction = async ({ queryKey }) => {
  try {
    const [_, company_id] = queryKey;
    const data = await requestAllEmployee(company_id);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const singleEmployeeAction = async ({ queryKey }) => {
  try {
    const [_, company_id, employee_id] = queryKey;
    const data = await requestSingleEmployee(company_id, employee_id);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createEmployeeAction = async ({ company_id, formData }) => {
  try {
    const data = await requestCreateEmployee(company_id, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const editEmployeeAction = async ({ company_id, employee_id, formData }) => {
  try {
    const data = await requestEditEmployee(company_id, employee_id, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const editShippingDetailsAction = async ({ company_id, employee_id, formData }) => {
  try {
    const data = await requestEditShippingDetails(company_id, employee_id, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteEmployeeAction = async ({ company_id, employee_id }) => {
  try {
    const data = await requestDeleteEmployee(company_id, employee_id);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const buyAction = async ({ company_id, cart_id, formData }) => {
  try {
    const data = await requestBuy(company_id, cart_id, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const buyOrderAction = async ({ company_id, selectedOrderId, formDataForOrders }) => {
  try {
    const data = await requestOrderBuy(company_id, selectedOrderId, formDataForOrders);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchAssetVault = async ({ company_id: userId, value: type }) => {
  try {
    const data = await requestFetchAssetVault(userId, type);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchAssetCategories = async ({ queryKey }) => {
  try {
    const [_, company_id] = queryKey;
    const data = await requestFetchAssetCategories(company_id);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchPublicAssetCategories = async () => {
  try {
    const data = await requestPublicFetchAssetCategories();
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const personnelInvitationAction = async ({ company_id, formData }) => {
  try {
    const data = await requestPersonnelInvitation(company_id, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const allPersonnelAction = async ({ queryKey }) => {
  try {
    const [_, company_id] = queryKey;
    const data = await requestAllPersonnel(company_id);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const singlePersonnelAction = async ({ company_id, personnel_id }) => {
  try {
    const data = await requestSinglePersonnel(company_id, personnel_id);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const allOrderHistory = async ({ queryKey }) => {
  try {
    const [_, company_id, type, category] = queryKey;
    const data = await requestAllOrderHistory(company_id, type, category);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const editPersonnelAction = async ({ company_id, personnel_id, formData }) => {
  try {
    const data = await requestEditPersonnelDetails(company_id, personnel_id, formData);
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const orderDetails = async ({ queryKey }) => {
  try {
    const [_, company_id, orderId] = queryKey;
    const data = await requestOrderDetail(company_id, orderId);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const resendPersonnelInvitationAction = async ({ company_id, personnel_id }) => {
  try {
    const data = await requestResendPersonnelInvitation(company_id, personnel_id);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deletePersonnelInvitationAction = async ({ company_id, personnel_id }) => {
  try {
    const data = await requestDeletePersonnelInvitation(company_id, personnel_id);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const shippingOptionAction = async ({ queryKey }) => {
  try {
    const [_, company_id] = queryKey;
    const data = await requestShippingOptions(company_id);
    _;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createAssetAction = async ([company_id, formData]) => {
  try {
    const data = await requestCreateAsset(company_id, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchAssetAction = async ({ queryKey }) => {
  try {
    const data = await requestFetchAsset(queryKey[1], queryKey[2]);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchBanksction = async ({ queryKey }) => {
  try {
    const data = await requestFetchABanks(queryKey[1]);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchAccountInfoAction = async ({ queryKey }) => {
  try {
    const data = await requestFetchAccountInfo(queryKey[1], queryKey[2], queryKey[3]);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchOrderAssets = async ({ queryKey }) => {
  try {
    const data = await requestFetchOrderAssets(queryKey[1], queryKey[2]);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const saveAccountInfoaction = async ({ queryKey }) => {
  try {
    const data = await requestSaveAccountInfo(queryKey[1], queryKey[2]);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const saveDeliveryAddress = async (companyId, orderId, deliveryAddress) => {
  try {
    const data = await requestSaveDeliveryAddress(companyId, orderId, deliveryAddress);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const offboardAsset = async (companyId, employeeId, AssetIds) => {
  const payload = {
    employee_id: employeeId,
    asset_ids: AssetIds
  };

  try {
    const data = await requestOffBoardAsset(companyId, payload);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};
