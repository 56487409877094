import styled from 'styled-components';

export const EmployeeTypeStyle = styled.label`
  input {
    display: none;
  }

  span {
    margin: 0.5rem 0.5rem;
    padding: 0;
    width: auto;
    border: 0;
    display: block;
    border-radius: 8px;
    position: relative;
    text-align: center;
    cursor: pointer;
    background: transparent;
  }

  .hobbies-icon {
    width: 100%;

    img {
      width: 100%;
      object-fit: fill;
    }
  }

  input:hover + .radio-btn > .overlay,
  input:checked + .radio-btn > .overlay {
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 24px;
    z-index: 1;
    /* background: rgb(242, 247, 255, 0.5); */
    border: 2px solid #0C66FF;
    transition: 0.7s ease;
  }

  @media screen and (max-width: 550px) {
    span {
      margin: 1rem 0;
    }
  }
`;
