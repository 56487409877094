import styled from 'styled-components';

export const TrackingReportStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  padding: 16px 0;

  .trc_aside_left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .trc_aside_left > .details {
    margin-left: 12px;

    h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #101828;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-decoration-line: underline;
      color: #004ccc;
      cursor: pointer;
    }
  }

  .trc_aside_right {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .delivered {
    color: #039855;
  }

  .in_transit {
    color: #f79009;
  }

  .awaiting_delivery {
    color: #003999;
  }
`;
