import styled from 'styled-components';

export const CheckboxDropdownStyles = styled.div`
  .cbds_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
    }

    svg {
      color: #667085;
    }
  }

  .cbds_content {
  }

  .add_sticky {
    overflow-y: scroll;
    height: 280px;
  }
`;
