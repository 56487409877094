import React from 'react';
import { Link } from 'react-scroll';
import { fb_icon, linkedIn_icon, twitter_icon } from '../../../assets/images';
import { Logo } from '../../shared';
import { footerdata } from './data';
import { FooterStyles } from './footerStyles';

const Footer = () => {
  // BsTwitter
  const socialMediaAddresses = {
    [`${twitter_icon}`]: 'https://www.twitter.com/therayda',
    [`${linkedIn_icon}`]: 'https://www.linkedin.com/company/raydahq/',
    [`${fb_icon}`]: 'https://www.facebook.com/myraydaHQ/'
  };

  const data_related = {
    [`Terms`]: 'https://www.twitter.com/therayda',
    [`Privacy`]: 'https://www.linkedin.com/company/raydahq/',
    [`Cookies`]: 'https://www.facebook.com/myraydaHQ/'
  };
  return (
    <FooterStyles>
      <section className="footer-col-1">
        <Logo footer />
        <div className="route_and_externalLinks">
          <div className="routes">
            {footerdata?.map(({ id, name, path }, index) => (
              <Link
                className="nav_link"
                activeClass="activeClassName"
                to={path}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                key={index}
              >
                {name}
              </Link>
            ))}
          </div>
          <div className="externalLinks">
            {[twitter_icon, linkedIn_icon, fb_icon].map((item, index) => (
              <a key={index} href={socialMediaAddresses[`${item}`]} target="_blank" rel="noreferrer">
                <img src={item} alt={`${item}`} />
              </a>
            ))}
          </div>
        </div>
      </section>

      <section className="footer-col-2">
        <div className="copyRight">
          <p>©{new Date().getFullYear()}. All right reserved</p>
        </div>
        <div className="data_related">
          {[`Terms`, `Privacy`, `Cookies`].map((item, index) => (
            <a key={index} href={data_related[`${item}`]} target="_blank" rel="noreferrer">
              <small>{item}</small>
            </a>
          ))}
        </div>
      </section>
    </FooterStyles>
  );
};

export default Footer;
