import styled from 'styled-components';

export const OrderItemsCardStyle = styled.article`
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 12px 0 20px 0;
  border-bottom: 1px solid #eaecf0;
  width: 100%;

  .image_area {
    width: 56px;
  }

  .orderpage_image_area {
    width: 176px;
    border-radius: 12px;
  }

  .image_container {
    width: 100%;

    img {
      width: 100%;
      object-fit: fill;
      border-radius: 12px;
    }
  }

  .main_content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 18px;
  }

  .sub_main_content {
    .h3,
    .orderpage_h3 {
      color: #101828;
    }

    .orderpage_h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }

    .h3 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .amount_area {
    width: 30%;

    .amount_text_orderpage,
    .amount_text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .sub_main_content > .tags_container_area {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .tags_container,
    .tags_card {
      display: flex;
      align-items: center;
    }

    .p_tag {
      margin-right: 12px;
    }

    .tags_container > .p_tag,
    .tags_container > .quantity {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #667085;
    }

    .tags_container > .tags_card > .swatch_item {
      background: #344054;
      opacity: 0.8;
      border-radius: 9999px;
      width: 8px;
      height: 8px;
      margin-right: 0.5rem;
    }

    .tags_container > .tags_card > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }
  }

  @media screen and (max-width: 550px) {
    .main_content {
      flex-direction: column;
      align-items: flex-start;
    }

    .amount_area {
      margin-top: 12px;
    }
  }

  @media screen and (max-width: 481px) {
    .orderpage_image_area {
      width: 146px;
    }

    .sub_main_content {
      .orderpage_h3 {
        font-weight: 500;
        font-size: 20px;
      }
    }
  }

  @media (max-width: 280px) {
    .orderpage_image_area {
      width: 76px;
    }

    .sub_main_content > .orderpage_h3 {
      font-weight: 500;
      font-size: 18px;
    }
  }
`;
