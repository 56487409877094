import styled from 'styled-components';

const HeaderStyle = styled.div`
  .app__navbar {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 0.8rem;
    align-items: center;
  }

  .app__navbar > .nav_section {
    display: flex;
  }

  .app_navbar-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0.6rem;

    li {
      margin: 0 1rem;
      list-style: none;

      .nav_link,
      .nav_link_portal {
        text-decoration: none;
        font-weight: 600;
        font-size: ${(props) => props.theme.fontSizes.text_base};
      }

      .nav_link {
        color: ${(props) => props.theme.colors.basic_white};
        cursor: pointer;

        &:hover {
          color: ${(props) => props.theme.colors.neutral_400};
        }
      }

      .nav_link_portal {
        color: ${(props) => props.theme.colors.primary_950};
        cursor: pointer;

        &:hover {
          color: ${(props) => props.theme.colors.neutral_400};
        }
      }
    }
  }

  .nav_btn_container {
    display: flex;

    width: 130px;
    font-size: 14px;
  }

  .nav_btn_container_portal {
    display: flex;
    align-items: center;
    width: 180px;
    justify-content: space-between;

    svg {
      font-size: 20px;
      color: #667085;
      cursor: pointer;
    }
  }
  ${
    '' /* .nav_btn_container {
    display: flex;

    width: 230px;
    font-size: 14px;

    .meta {
      margin: 0 0.3rem;
    }
  } */
  }

  .app__navbar_mobile {
    display: none;
  }

  ${
    '' /* @media (min-width: 1024px) and (max-width: 1280px) {
    .app__navbar {
      padding: 1rem 2rem;
    }
  
  
  } */
  }

  .profile-dropdown {
    background: #ffffff;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 0.5rem;

    position: absolute;
    width: 14rem;
    height: 6.75rem;
    right: 2rem;
    top: 4.5rem;
    z-index: 2000;

    display: flex;
    flex-direction: column;

    &__user-info {
      display: flex;
      padding: 0.75rem 0.7rem;
      justify-content: space-between;
      gap: 0.1rem;

      &--email {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.875rem;
        line-height: 1.125rem;

        & :first-child {
          font-weight: 600;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      border-top: 1px solid #eaecf0;

      & span {
        height: 2.375rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.5625rem 0.625rem;

        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #344054;
        cursor: pointer;

        & :first-child {
          margin: 0 0.4rem;
        }
      }
    }
  }

  @media (min-width: 1281px) {
    .app__navbar {
      padding: 1rem 4rem;
    }
  }

  @media screen and (max-width: 912px) {
    .app__navbar {
      display: none;
    }

    .app__navbar_mobile {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      svg {
        color: #003999;
      }
    }

    .xs_mobile_nav_btn_container_portal {
      display: none;
    }

    .mobile_nav_btn_container_portal {
      display: flex;
    }

    .xs_mobile_nav_btn_container_portal,
    .mobile_nav_btn_container_portal {
      align-items: center;
      width: 160px;
      justify-content: space-between;

      svg {
        font-size: 20px;
        color: #667085;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 414px) {
    .app__navbar_mobile > .mobile_nav_btn_container_portal {
      width: 140px;
    }
  }

  @media (max-width: 280px) {
    .app__navbar_mobile {
      padding: 1rem 0.4rem;
    }
    .app__navbar_mobile > .mobile_nav_btn_container_portal {
      display: none;
    }

    .xs_mobile_nav_btn_container_portal {
      display: flex;
      width: 50px;
    }
  }
`;

export { HeaderStyle };
