import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomModal from '../../shared/CustomModal/CustomModal';
import { EditShippingDetailsModalStyle } from './EditShippingDetailsModalStyle';
import { Button, CustomInput, CustomLoader, CustomSelect, CustomTelInput } from '../../shared';
import { country_state } from '../../../utils/Data/countries';
import { shipping_contact_icon } from '../../../assets/icons';
import { AppContext } from '../../../utils';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { editShippingDetailsAction } from '../../../stateManagement/reactQuery/actions/portalActions';

const EditShippingDetailsModal = ({ company_id, employee_id, telephone, address, city, state, country, landmark }) => {
  const queryClient = useQueryClient();

  const [state_province, setState_province] = useState([]);

  const { openEditShippingDetails, setOpenEditShippingDetails } = useContext(AppContext);
  const [isDisAbled, setIsDisAbled] = useState(true);
  const [spinner, setSpinner] = useState(false);

  const { mutateAsync: handleEditShippingDetails } = useMutation((variables) => editShippingDetailsAction(variables), {
    onSuccess: (response) => {
        const {
            data: { success }
          } = response;

      if (success) {
        setSpinner(false);
        toast.success(`Successfully updated`);
        
        // location.reload();
        queryClient.invalidateQueries('single_employee');
        queryClient.invalidateQueries('all_employee');
        setOpenEditShippingDetails((prev) => !prev);
      }
    },

    onError: (error) => {
      toast.error(`Something went wrong: ${error.message}`);
    }
  });
  const shipping_formik = useFormik({
    initialValues: {
      telephone: '',
      country: '',
      state: '',
      address: '',
      city: '',
      landmark: ''
    },
    validationSchema: Yup.object().shape({
      telephone: Yup.string().required('Phone number is required'),
      address: Yup.string().required('Address is required'),
      country: Yup.string().required('Country Of residence is required'),
      state: Yup.string().required('State / Province is required'),
      city: Yup.string().required('City is required')
    }),

    onSubmit: async (values) => {
      try {
        setSpinner(true);

        const formData = {
          telephone: values.telephone,
          address: values.address,
          city: values.city,
          state: values.state,
          landmark: values.landmark,
          country: values.country
        };

        await handleEditShippingDetails({ company_id, employee_id, formData });
      } catch (error) {
        setSpinner(false);
      } finally {
        setSpinner(false);
      }
    }
  });

  useEffect(() => {
    if (
      shipping_formik.values.telephone !== '' &&
      shipping_formik.values.country !== '' &&
      shipping_formik.values.state !== '' &&
      shipping_formik.values.address !== '' &&
      shipping_formik.values.city !== ''
    ) {
      setIsDisAbled(false);
    } else {
      setIsDisAbled(true);
    }
  }, [
    shipping_formik.values.telephone,
    shipping_formik.values.country,
    shipping_formik.values.state,
    shipping_formik.values.address,
    shipping_formik.values.city
  ]);

  useEffect(() => {
    const findeData = country_state.filter((item) => item.country === shipping_formik.values.country);
    setState_province(findeData[0]?.states);
  }, [shipping_formik.values.country]);

//   const getOfficeLocation = (location) => {
//     if (location.address) {
//       shipping_formik.setFieldValue('address', location?.address);
//     }
//   };

  useEffect(() => {
    shipping_formik.setFieldValue('telephone', telephone);
    shipping_formik.setFieldValue('address', address);
    shipping_formik.setFieldValue('city', city);
    shipping_formik.setFieldValue('state', state);
    shipping_formik.setFieldValue('country', country);
    shipping_formik.setFieldValue('landmark', landmark);
  }, [telephone, address, city, state, country, landmark]);

  return (
    <CustomModal
      portal
      open={openEditShippingDetails}
      hasHeader
      setClose={setOpenEditShippingDetails}
      image={shipping_contact_icon}
    >
      <EditShippingDetailsModalStyle onSubmit={shipping_formik.handleSubmit}>
        <div className="header_text">
          <h1>Employee info</h1>
          <p>Edit your employee information here.</p>

          <hr />
        </div>

        <div className="form_container">
          <CustomTelInput
            label={'Phone number'}
            name="telephone"
            value={shipping_formik.values.telephone}
            handleBlur={shipping_formik.handleBlur}
            getValue={(value) => shipping_formik.setFieldValue('telephone', value)}
          >
            {shipping_formik.touched.telephone && shipping_formik.errors.telephone ? (
              <div style={{ color: 'red', fontSize: '12px' }}>{shipping_formik.errors.telephone}</div>
            ) : null}
          </CustomTelInput>
          <CustomSelect
            label={'Country Of Residence'}
            isDefault
            optionData={country_state}
            select_by={!country_state?.length < 1 ? 'country' : ''}
            defaultStructure
            defaultValue={[{ label: `${country}`, value: `${country}` }]}
            placeholder={'Select country of residence'}
            handleChange={async ({ value }) => shipping_formik.setFieldValue('country', value)}
            handleBlur={shipping_formik.handleBlur}
          />
          <CustomSelect
            label={'State / Province'}
            optionData={state_province}
            isDefault
            defaultValue={[{ label: `${state}`, value: `${state}` }]}
            placeholder={'Select state / province'}
            handleChange={async ({ value }) => shipping_formik.setFieldValue('state', value)}
            handleBlur={shipping_formik.handleBlur}
          />
          <CustomInput
            label={'City'}
            name="city"
            type="text"
            placeholder={'Enter city'}
            value={shipping_formik.values.city}
            handleChange={shipping_formik.handleChange}
            handleBlur={shipping_formik.handleBlur}
          >
            {shipping_formik.touched.city && shipping_formik.errors.city ? (
              <div style={{ color: 'red', fontSize: '12px' }}>{shipping_formik.errors.city}</div>
            ) : null}
          </CustomInput>
          {/* <MapLocationFinder getOfficeLocation={getOfficeLocation} label={"Address"} defaultValue={address}/> */}
          <CustomInput
            label={'Address'}
            name="address"
            type="text"
            placeholder={'Enter employee address'}
            value={shipping_formik.values.address}
            handleChange={shipping_formik.handleChange}
            handleBlur={shipping_formik.handleBlur}
          >
            {shipping_formik.touched.address && shipping_formik.errors.address ? (
              <div style={{ color: 'red', fontSize: '12px' }}>{shipping_formik.errors.address}</div>
            ) : null}
          </CustomInput>

          <CustomInput
            label={'Nearest Landmark'}
            name="landmark"
            type="text"
            placeholder={'Enter nearest landmark'}
            value={shipping_formik.values.landmark}
            handleChange={shipping_formik.handleChange}
            handleBlur={shipping_formik.handleBlur}
          ></CustomInput>

          <div className="meta" />
          {!spinner ? (
            <div className="ce_btn_container">
              <div className="btn_container">
                <div className="btn">
                  <Button
                    bg_default={true}
                    type={'button'}
                    handleClick={() => setOpenEditShippingDetails((prev) => !prev)}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="btn">
                  <Button bg_blue={true} type={'submit'} disabled={isDisAbled}>
                    Save Changes
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ margin: 'auto' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CustomLoader />
              </div>
            </div>
          )}
        </div>
      </EditShippingDetailsModalStyle>
    </CustomModal>
  );
};

export default EditShippingDetailsModal;
