import React, { useState } from 'react';
import { CustomInputStyles } from './CustomInputStyles';
import { BsEye, BsEyeSlash } from "react-icons/bs";

const CustomInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    required,
    name,
    Icon,
    label,
    placeholder,
    value,
    handleChange,
    type,
    handleBlur,
    handleFocus,
    children,
    lg,
    password,
    data_cy,
    ...otherProps
  } = props;
  return (
    <CustomInputStyles>
      {
        label && (
          <label htmlFor={name} className="form_label">
            {label}{' '}
          </label>
        )
      }
      

      {
        !password ? (
          <div className="input_container">
            {Icon && <Icon size={22} />}
            <input
              data-cy={data_cy}
              type={type}
              name={name}
              id={name}
              className="field"
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              
              {...otherProps}
            />
          </div>
        ) : (
          <div className="input_container">
            <input
              data-cy={data_cy}
              type={showPassword === true ? 'text':'password'}
              name={name}
              id={name}
              className="field"
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              {...otherProps}
            />

            {!showPassword && (<BsEyeSlash onClick={() => setShowPassword(!showPassword)}/>)}
            {showPassword && (<BsEye onClick={() => setShowPassword(!showPassword)}/>)}
          </div>
        )
      }
      
      {children}
    </CustomInputStyles>
  );
};

export default CustomInput;
