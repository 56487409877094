import React from 'react';
import { Link } from 'react-router-dom';
import { footer_logo, logo } from '../../../assets/images';
import { getUserData } from '../../../services/helper';
import { AppLogo } from './logoStyles';

const Logo = ({ footer }) => {
  return (
    <Link to={getUserData() ? '/portal/overview/dashboard' : '/'}>
      <AppLogo>
        {!footer ? <img src={logo} alt="rayda's logo" /> : <img src={footer_logo} alt="rayda's logo" />}
      </AppLogo>
    </Link>
  );
};

export default Logo;
