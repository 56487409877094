import { useContext } from 'react';
import { AppContext } from '../../../utils';
import { Button, CustomInput } from '../../shared';
import { SubscribeStyles } from './SubscribeStyles';

const Subscribe = () => {
  const { setShow } = useContext(AppContext);

  const handleOpen = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setShow((prev) => !prev);
  };
  return (
    <SubscribeStyles>
      <article className="subcribe_container">
        <section className="text_area">
          <h1 className="h1_text">Don't Miss Out</h1>
          <p className="p_text">Stay in the loop with everything you need to know.</p>
        </section>
        <section className="action_area">
          <div className="action_area_field">
            <div className="action_field">
              <CustomInput placeholder={'Enter your email'} />
              <small className="action_smtext">We care about your data in our privacy policy.</small>
            </div>

            <div className="content_btn">
              <Button bg_blue={true} handleClick={() => handleOpen()}>
                Subscribe
              </Button>
            </div>
          </div>
        </section>
      </article>
    </SubscribeStyles>
  );
};

export default Subscribe;
