const questionData = [
  {
    id: 1,
    title: 'What kind of items does Rayda Provide?',
    info: `
        We provide a wide range of items for your remote team from computers, phones, tablets, monitors to accessories covering a wide range of brands ( Apple, Dell, HP, IBM) etc.
      `
  },
  {
    id: 2,
    title: 'How quickly is the asset delivered?',
    info: `
      We are able to deliver the items within 48-72 hours from when it is ordered on our platform ensuring your team has the tools they need.
    `
  },
  {
    id: 3,
    title: 'Is the asset insured?',
    info: `
      Yes; all assets purchased via Rayda remote are insured via our partnerships with insurance providers across multiple countries. We provide you a detailed insurance certificate.
    `
  },
  {
    id: 4,
    title: 'Can you receive & dispose assets for us?',
    info: `
      We provide a receiving address for exiting employees to ship their devices to. We process the sale of the assets and return the value of the asset sold.
    `
  },
  {
    id: 5,
    title: 'Do you have an asset recovery service?',
    info: `
      We offer an asset recovery service, we will liaise with your exiting employee and have our logistics partner pickup the asset from them in record time.
    `
  },
  {
    id: 6,
    title: 'Does Rayda remote handle Data wipe request?',
    info: `
      We enable 
    `
  },
  {
    id: 7,
    title: 'Can I lease or do i have to purchase the asset outright?',
    info: `
      You can either purchase or lease any of the assets in our inventory
    `
  }
];
export { questionData };
