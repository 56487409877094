import { bg_md } from "../../../assets/images"
import { gotoRoute } from "../../../utils"
import { Button } from "../../shared"
import CustomGlobe from "../CustomGlobe/CustomGlobe"
import { BannerStyles } from "./bannerStyles"

const Banner = () => {

  return (
    <BannerStyles>
        <section className="banner__content">
            <h1 className='banner__title'>Equip Your Remote Team with Quality IT Assets</h1>
            <p className='banner__text'>
                Ensuring your team has the necessary IT 
                assets to do great work, no matter where 
                they are located.
            </p>
            <div className='banner__footer_btn'>
                <Button bg_default={true} handleClick={() => gotoRoute('https://calendly.com/abisoye-fadare/partner-onboarding-brief')} >Get Started</Button>
            </div>
        </section>
        <img src={bg_md} className="bg_md" alt='bg_md'/>
        <section className="banner__content_img">
          <CustomGlobe/>
        </section>
    </BannerStyles>
  )
}

export default Banner