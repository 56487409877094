import styled from 'styled-components';

export const ImpactStyles = styled.article`
  height: 590px;
  padding: 96px 3rem;
  font-family: 'IBM Plex Sans';
  font-style: normal;

  .meta {
    margin-top: 10px;
  }

  .ib_subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: ${(props) => props.theme.colors.neutral_750};
  }

  .ib_content {
    display: flex;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.neutral_50};
    border-radius: 16px;
    height: 240px;
    margin-top: 62px;
  }

  .ib_content_container {
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 64px;
  }

  .ib_content_card {
    h1 {
      font-weight: 600;
      font-size: 60px;
      line-height: 72px;
      text-align: center;
      letter-spacing: -0.02em;
      color: ${(props) => props.theme.colors.primary_600};
    }

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  }

  @media (min-width: 1281px) {
    padding: 96px 6rem;
  }

  @media screen and (max-width: 912px) {
    padding: 96px 2rem;
    height: auto;

    .ib_content_container {
      flex-direction: column;
      padding-bottom: 4rem;
    }

    .ib_content {
      height: auto;
    }

    .ib_content_card {
      margin-top: 4rem;
    }

    .ib_content_card > p {
      margin-top: 0.7rem;
    }
  }

  @media screen and (max-width: 768px) {
    .ib_content_card {
      margin-top: 3rem;
    }
    .ib_content_card {
      h1 {
        font-size: 48px;
      }
    }
  }

  @media screen and (max-width: 550px) {
    padding: 96px 1rem;
  }
`;
