import styled from 'styled-components';

export const MainstoreStyles = styled.div`
  .sidebar {
    display: flex;
    margin-top: 32px;
  }

  .sidebar__sidebar {
    padding: 0 1.5rem 1.5rem 1.5rem;
    width: 35%;
    border-right: 1px solid #eaecf0;

    .title_text {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
    }

    .suTitle_text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #475467;
    }

    .range_amount {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .range_amount > .range_filed_left,
    .range_amount > .range_filed_right {
      width: 48%;
    }

    .range_input {
      margin-top: 12px;
    }

    .range_input > .range_field {
      width: 100%;
    }

    .action_btn_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-item: center;

      .action_btn_submit {
        flex: 1;
      }

      .action_container {
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .action_btn_delete {
          background: #ffffff;
          outline: none;
          border: none;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 500px;
          width: 48px;
          height: 48px;
          cursor: pointer;

          img {
            object-fit: fill;
            width: 100%;
          }
        }
      }
    }
  }

  .sidebar__main {
    flex: 1;
    /* overflow-x: hidden; */
    overflow-y: scroll;
    scrollbar-width: thin;
    height: 80vmax;
    padding: 0 1.5rem 1.5rem 1.5rem;

    & > article {
      @media screen and (max-width: 90rem) {
        grid-template-columns: repeat(3, 1fr);
      }
     
      @media screen and (max-width: 64rem) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .items_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      width: 100%;
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 0;
      background: #ffffff;
    }

    .items_container > .search {
      flex: 1;
    }

    .items_container > .content_btn_container {
      width: 23%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .content_btn_container > .content_btn {
      width: 95%;
    }
  }

  @media screen and (max-width: 912px) {
    .sidebar__sidebar {
      display: none;
      padding: 0;
      width: 100%;
    }

    .sidebar__main {
      padding: 0;
    }
  }

  @media screen and (max-width: 550px) {
    .sidebar__main {
      padding: 0;
      height: auto;

      .items_container {
        flex-direction: column;
        justify-content: flex-start;
      }

      .items_container > .search {
        width: 100%;
      }

      .items_container > .content_btn_container {
        width: 100%;
        justify-content: flex-start;
        margin-top: 12px;
      }

      .content_btn_container > .content_btn {
        width: 100%;
      }
    }
  }
`;
