import React from 'react';
import { message_chat_circle, message_smile_circle, phone } from '../../../assets/images';
import { BenefitCard, Title } from '../../shared';
import { ContactUsStyles } from './ContactUsStyles';

const ContactUs = () => {
  return (
    <ContactUsStyles id="#contact-us">
      <section className="contact_us">
        <Title sm is_white text={'Contact us'} />
        <div className="meta" />
        <h1 className="h1_text">We’d love to hear from you</h1>
        <div className="meta" />
        <p className="p_text">Our team is always available to discuss and support your needs</p>
      </section>
      <section className="contact_us_cards">
        {[
          {
            image: message_smile_circle,
            title: `Chat to sales`,
            sm_body_text: `Speak to our friendly team.`,
            contact: `sales@rayda.co`,
            link: `mailto: sales@rayda.co`
          },
          {
            image: message_chat_circle,
            title: `Chat to support`,
            sm_body_text: `We’re here to help.`,
            contact: `hello@rayda.co`,
            link: `mailto: hello@rayda.co`
          },
          {
            image: phone,
            title: `Call us`,
            sm_body_text: `Mon-Fri from 9am to 5pm.`,
            contact: ` +1 (302) 261 5184`,
            link: `tel: +1 (302) 261 5184`
          }
        ].map(({ image, title, sm_body_text, contact, link }, index) => (
          <BenefitCard key={index} sm image={image} title={title} sm_body_text={sm_body_text}>
            <a style={{ textDecoration: 'none', color: '#003999', cursor: 'pointer' }} href={link}>
              {contact}
            </a>
          </BenefitCard>
        ))}
      </section>
    </ContactUsStyles>
  );
};

export default ContactUs;
