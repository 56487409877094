import React, {forwardRef} from 'react'
import { InitialsAvatarStyle } from './InitialsAvatarStyle'

const InitialsAvatar = forwardRef(({initials, ...otherProps}, ref) => {
  return (
    <InitialsAvatarStyle ref={ref} {...otherProps}>
        <h1 className="avatar__text">{initials?.toUpperCase()}</h1>
    </InitialsAvatarStyle>
  )
})

InitialsAvatar.displayName = 'InitialsAvatar';

export default InitialsAvatar