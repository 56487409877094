import React from 'react'
import { lazy_loader } from '../../../assets/images'
import toCurrency from '../../../utils/helpers/toCurrency'
import { OrderItemsCardStyle } from './OrderItemsCardStyle'

const OrderItemsCard = ({image, header, tag, amount, orderpage, currency, oreder_num, quantity, custom_qunatity}) => {
  return (
    <OrderItemsCardStyle>
        <section className={orderpage ? 'orderpage_image_area' : 'image_area'}>
            <div className='image_container'>
                <img src={!image ? lazy_loader : image } alt="order-item-card-image" />
            </div>
        </section>
        <section className={orderpage ? 'main_content orderpage_main_content' : 'main_content'}>
            <div className='sub_main_content'>
                <h3 className={orderpage ? "orderpage_h3" : "h3" }>{header}</h3>

                {
                    tag || orderpage || custom_qunatity && (
                        <div className='tags_container_area'>
                            {
                                tag && (
                                    <div className='tags_container'>
                                        {
                                            tag?.map((item, index) => (
                                                <div className='tags_card' key={index}>
                                                    <div className="swatch_item"/>
                                                    <p>{item}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }
                            {
                                orderpage && (
                                    <div className='tags_container'>
                                        {
                                            oreder_num && (
                                                <p className='p_tag'>Order Number: { oreder_num}</p>
                                            )
                                        }
                                        
                                        <p className='p_tag quantity'>Quantity: {quantity}</p>
                                    </div>
                                )
                            }

                            {
                                custom_qunatity && <p className='p_tag quantity'>Quantity: {quantity}</p>
                            }
                        </div>
                    )
                }
                
                
            </div>
            {
                amount && (
                    <section className='amount_area'>
                        <h3 className={orderpage ? "amount_text_orderpage" : "amount_text"}>{currency ? toCurrency(amount, currency) : amount}</h3>
                    </section> 
                )
            }
        </section>
  

        {/* {
            amount && (
                <section className='amount_area'>
                    <h3 className={orderpage ? "amount_text_orderpage" : "amount_text"}>{currency ? toCurrency(amount, currency) : amount}</h3>
                </section> 
            )
        } */}
        

    </OrderItemsCardStyle>
  )
}

export default OrderItemsCard