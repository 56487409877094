import styled from 'styled-components';

const TextSmStyles = styled.p`
  color: #5d6b98;
`;

const TextSmStylesWhite = styled.p`
  color: #b9c0d4;
`;

export { TextSmStyles, TextSmStylesWhite };
