import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { updateCartAction } from '../../../stateManagement/reactQuery/actions/portalActions'
import { debounce } from '../../../utils/helpers/debounce'
import { StepperInputStyles } from './StepperInputStyles'

const StepperInput = ({name, value, handleChange, initialState, setState, sm, company_id, cart_id, asset_id, currency, checkItemExistence, useCheckState}) => {

  const queryClient = useQueryClient();
 
  const {mutateAsync: handleUpdateCart } = useMutation((variables) => updateCartAction(variables),
      {
          onSuccess: (response) => {
              const { data : {status} } = response;
              

              if(status === "success"){
                  toast.success(`Item successfully updated`);
                  queryClient.invalidateQueries('cart_items');
              }
          },

          onError: (error) => {
              toast.error(`Something went wrong: ${error.message}`)
          }
              
      }
  );

  const handle_addCart = async () => {

    try {
       
        const formData = {
            asset_id,
            quantity: initialState.count,
            currency
        }

        if(useCheckState){
            if(checkItemExistence){
                return await handleUpdateCart({company_id, cart_id, formData});
            } else {
                return null;
            }
            
          } else {
            await handleUpdateCart({company_id, cart_id, formData});
          }
   
        
    
    } catch (error) {

    } 
}




  const handleIncrement = () => {
      setState({
          count: ++initialState.count
    })


    handle_addCart()

    // if(sm){
    //   handle_addCart()
    // }

  }

  const handleDecrement = () => {
    setState({
          count: --initialState.count
      })

      handle_addCart()

    //   if(sm){
    //     handle_addCart()
    //   }
  }

  const optimizedIncrement = useCallback(debounce(handleIncrement), [initialState.count]);
  const optimizeDecrement = useCallback(debounce(handleDecrement), [initialState.count]);

  
  return (
    <StepperInputStyles>
        <button className={!sm ? "stepper-input__button" : "sm_stepper-input__button"} onClick={ !sm ? handleDecrement : optimizeDecrement}>-</button>
        <div className={!sm ? "stepper-input__content" : "sm_stepper-input__content"}>
            <input type="text" className={!sm ? "stepper-input__input" : "sm_stepper-input__input"} name={name} disabled value={value} onChange={handleChange} />
        </div>
        <button className={!sm ? "stepper-input__button" : "sm_stepper-input__button"} onClick={!sm ? handleIncrement : optimizedIncrement}>+</button>
    </StepperInputStyles>
  )
}

export default StepperInput