import { dispatchMethod } from '../helper';
import { SET_ALLPRODUCT } from '../types';

export const productsAction = (products) => async (dispatch) => {
  if (products) {
    const payload = {
      products
    };

    dispatchMethod(SET_ALLPRODUCT, payload, dispatch);
  }
};
