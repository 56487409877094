import styled from 'styled-components';

export const MapLocationStyles = styled.div`
  width: 100%;
  position: relative;

  .form_label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 26px 0 12px 0;
    display: block;
  }

  .input_container {
    width: inherit;
    background: transparent;
    border: 0;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0 0 0 12px;
    height: 48px;
  }

  .location-search-input {
    width: 98%;
    height: 46px;
    outline-style: none;
    border: 0;
  }

  .field {
    width: 100%;
    height: inherit;
    background: transparent;
    border: 0;
    outline-style: none;
    font-size: 16px;
  }

  @media screen and (max-width: 920px) {
    .place_input {
      width: 96.5%;
    }
  }

  @media screen and (max-width: 550px) {
    .place_input {
      width: 93%;
    }
  }
`;
