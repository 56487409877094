import { CustomCheckboxStyles } from "./CustomCheckboxStyles"

const CustomCheckbox = ({id, name, value, children, handleChange, refKey}) => {
  return (
    <CustomCheckboxStyles>
        <input className="form-check-input" key={refKey || ""} onChange={handleChange} type="checkbox" id={id} name={name} value={value} />
        <label className="form-check-label" htmlFor={name}>
            {children}
        </label>
    </CustomCheckboxStyles>
  )
}

export default CustomCheckbox