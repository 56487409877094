import { asset_delivery, asset_insurance, it_asset_purchase_lease, onboard_content_img } from '../../../assets/images';
import { TitlespacedContent } from '../../shared';
import { OnboardingStyles } from './OnboardingStyles';

const Onboarding = () => {
  return (
    <OnboardingStyles id="#onboarding">
      <TitlespacedContent
        title_sm={'Onboarding'}
        title_lg={'Get Your New Hires Up and Running Quickly'}
        description={
          'Selection of top of the line IT assets, fast delivery through leading logistics partners, and full insurance coverage'
        }
        data={[
          {
            title: 'IT asset purchase/lease',
            content: `Ensure your new hires are off to a successful start by providing them with our top-quality IT assets. Our inventory offers a wide range of options to choose from, ensuring you find the perfect fit for your company's needs.`,
            imageFile: it_asset_purchase_lease,
            imageId: '01'
          },
          {
            title: 'Asset Delivery',
            content: `Don't let the wait for assets hold back your new hires from getting to work. With our fast delivery service, we'll get the necessary assets to your new employees in record time.`,
            imageFile: asset_delivery,
            imageId: '02'
          },
          {
            title: 'Asset insurance',
            content:
              'Ensure that your assets are protected against any potential damages or losses with our comprehensive insurance policy. Giving you peace of mind knowing that your investment is fully insured.',
            imageFile: asset_insurance,
            imageId: '03'
          }
        ]}
        image={onboard_content_img}
      />
    </OnboardingStyles>
  );
};

export default Onboarding;
