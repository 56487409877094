import styled from 'styled-components';

export const SectionCardStyles = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 64px;
  width: 100%;
  ${'' /* color: ${(props) => props.theme.colors.basic_white}; */}

  .card_text_content {
    width: 55%;
    display: flex;
    flex-direction: column;
  }

  .card_image_content {
    width: 45%;
  }

  .card_image_container {
    width: 100%;

    img {
      width: 100%;
      object-fit: fill;
    }
  }

  @media screen and (max-width: 912px) {
    flex-direction: column;
    align-items: flex-start;

    .card_text_content {
      width: 100%;
    }

    .card_image_content {
      width: 100%;
      margin-top: 4rem;
    }
  }
`;
