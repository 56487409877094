import styled from 'styled-components';

const AccordionStyles = styled.article`
  border: 0;
  border-bottom: 1px solid #dcdfea;
  padding: 1.7rem 0;
  width: 100%;

  .accordion_header {
    color: ${(props) => props.theme.colors.primary_950};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .btn {
      border: 0;
      outine-style: none;
      color: ${(props) => props.theme.colors.neutral_650};
      background: transparent;
      cursor: pointer;
    }
  }

  .accordion_body {
    margin-top: 1rem;
    color: #7d89b0;
    line-height: 28px;
  }
`;

export { AccordionStyles };
