import styled from 'styled-components';

export const CustomInputStyles = styled.div`
  width: 100%;

  .form_label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 26px 0 12px 0;
    display: block;
  }

  .input_container {
    width: inherit;
    background: transparent;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 0 0 0 12px;
    height: 48px;

    svg {
      color: #667085;
      margin-right: 10px;
    }
  }

  .field {
    width: inherit;
    height: inherit;
    background: transparent;
    border: 0;
    outline-style: none;
    font-size: 16px;
  }
`;
