import styled from 'styled-components';

const FaqStyles = styled.article`
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  align-items: center;
  height: 1230px;

  .contact_text_sm,
  .text_sm {
    width: 600px;
    text-align: center;
    line-height: 30px;
  }

  .text_sm {
    margin-top: 15px;
  }

  .contact_text_sm {
    margin-top: 1rem;
  }

  .content_body {
    width: 600px;
    margin-top: 30px;
  }

  .content_footer {
    width: 100%;
    padding: 0 6rem;
  }

  .content_footer_container {
    margin-top: 4rem;
    height: 298px;
    width: inherit;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    background: ${(props) => props.theme.colors.neutral_30};
  }

  .faq_avatar {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .content_footer_container > .content_btn {
    width: 130px;
    margin-top: 2rem;
  }

  @media screen and (max-width: 912px) {
    padding: 4rem 2rem 4rem 2rem;
    height: auto;

    .contact_text_sm,
    .text_sm,
    .content_body {
      width: 100%;
    }

    .content_footer {
      padding: 0;
    }

    .content_footer_container {
      height: auto;
    }
  }

  @media screen and (max-width: 550px) {
    padding: 4rem 1rem 4rem 1rem;

    .content_footer {
      ${'' /* width: 1216px; */}
      ${'' /* height: 298px; */}
    }
  }

  @media (max-width: 320px) {
    padding: 4rem 1rem 2rem 1rem;
  }
`;

export { FaqStyles };
