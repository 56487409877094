import { SectionCardStyles } from './SectionCardStyles';

const SectionCard = ({ children, image, reverse }) => {
  return (
    <>
      {!reverse ? (
        <SectionCardStyles>
          <div className="card_text_content">{children}</div>

          <div className="card_image_content">
            <div className="card_image_container">
              <img src={image} alt={`${image}`} />
            </div>
          </div>
        </SectionCardStyles>
      ) : null}
    </>
  );
};

export default SectionCard;
