import styled from 'styled-components';
const PortalHeaderStyle = styled.div`
  width: 100%;

  .sticky {
    position: sticky;
    padding: 0.17rem 2rem;
    background: ${(props) => props.theme.colors.basic_white};
    box-shadow: 0px 0.5px 0px -2px #dcdfea;
    border-bottom: 1px solid #eaecf0;
  }

  .normal {
    background: #f9f9fb !important;
    box-shadow: 0px 0.5px 0px -2px #dcdfea;
  }

  .unSticky {
    position: unSticky;
    padding: 0.17rem 2rem;
    background: ${(props) => props.theme.colors.basic_white};
    box-shadow: 0px 0.5px 0px -2px #dcdfea;
    border-bottom: 1px solid #eaecf0;
  }

  @media screen and (max-width: 912px) {
    .sticky {
      padding: 0 0.6rem 0 0;
    }

    .unSticky {
      padding: 0 0.6rem 0 0;
    }
  }
`;

export { PortalHeaderStyle };
