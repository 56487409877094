import React from 'react'
import { checkbox } from '../../../assets/icons';
import { DeliveryTypeStyle } from './DeliveryType'

const DeliveryType = (props) => {
    const {
        id,
        name,
        value,
        handleCheckedElement,
        image,
        title,
        sub_title
    } = props;

  return (
    <DeliveryTypeStyle>
        <input className='input'
            type="radio"
            name={name}
            value={value}
            id={id}
            onChange={handleCheckedElement}
        />
        
        <span className="radio-btn">
            <img src={image} alt="card_img" className='card_img' />

            <div className="content_area">
                <p className='title'>{title}</p>
                <p className='sub_title'>{sub_title}</p>
            </div>

            <div className='rounded_content'/>
            <img src={checkbox} alt="check_box" className='check_box' />
        </span>
    </DeliveryTypeStyle>
  )
}

export default DeliveryType