import { forwardRef, useEffect, useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { MapLocationStyles } from './MapLocationStyle';



const MapLocationFinder = forwardRef((props, ref) => {
    const {getOfficeLocation, children, defaultValue, reset, label, specificCountry = null} = props;

    const [address, setAddress] = useState('');

    const handleChange = address => {
        setAddress(address);
    };

    useEffect(() => {
        if(defaultValue){
            if(typeof defaultValue === 'object'){
                setAddress(defaultValue.address);
            } else {
                setAddress(defaultValue);
            }
            
            
        }

    }, [defaultValue]);

    useEffect(() => {
        if(reset){
            setAddress("");
            
        }

    }, [reset])

    

    const handleSelect = async address => {
        setAddress(address);

        geocodeByAddress(address)
            .then(async results => {
                const { address_components } = results[0];
                const len = address_components.length;

                const latLng = await getLatLng(results[0]);

                let country = '';
                let state = '';
                let city = '';
                let isoCode = '';

                if (address_components[len - 2]?.long_name) country = address_components[len - 2].long_name;
                if (address_components[len - 3]?.long_name) state = address_components[len - 3].long_name;
                if (address_components[len - 5]?.long_name) city = address_components[len - 5].long_name;
                if (address_components[len - 2]?.short_name) isoCode = address_components[len - 2].short_name;

                getOfficeLocation({ lat: latLng.lat, lng: latLng.lng, address, city, state, country, isoCode });
            })
    };

    const searchOptions = {
        componentRestrictions: { country: [specificCountry] },
        // types: ['city']
      }

    return (
        <MapLocationStyles>
            <label htmlFor='address' className="form_label">{label}</label>
            <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
                searchOptions={specificCountry && searchOptions }
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="input_container">
                        <input className='field' ref={ref}
                            {...getInputProps({
                                placeholder: 'Start typing address here...',
                                className: 'location-search-input form-control',
                                id: 'address'
                                
                            })}
                        />
                        <div className="autocomplete-dropdown-container" style={{
                                position: 'absolute',
                                backgroundColor: 'white',
                                zIndex: 2,
                        }}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = { backgroundColor: '#fafafa', cursor: 'pointer', padding: '5px 20px'};
                                return (
                                    <div key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
            {children}
        </MapLocationStyles>
    );
})

MapLocationFinder.displayName = 'MapLocationFinder';

export default MapLocationFinder;