import React from 'react'
import InitialsAvatar from '../InitialsAvatar/InitialsAvatar'
import { TrackingReportStyle } from './TrackingReportStyle'
import { Link } from 'react-router-dom'

const TrackingReportCard = ({initials, status, full_name, order_id, transaction_reference}) => {
  return (
    <TrackingReportStyle>
        <div className='trc_aside_left'>
            <InitialsAvatar initials={initials}/>
            <div className="details">
                <h2>{full_name}</h2>
                <Link to={`/portal/orders/${transaction_reference}`}>Order: {order_id}</Link>
            </div>
        </div>

        {
            status === "delivered" && (
                <p className='trc_aside_right delivered'>Delivered</p>
            )
        }
        
        {
            status === "picked_up" && (
                <p className='trc_aside_right delivered'>Picked Up</p>
            )
        }
        {
            status === "in_transit" && (
                <p className='trc_aside_right in_transit'>In Transit</p>
            )
        }
        {
            status === "awaiting_delivery" && (
                <p className='trc_aside_right awaiting_delivery'>Awaiting Delivery</p>
            )
        }
        
    </TrackingReportStyle>
  )
}

export default TrackingReportCard