import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const ProtectedVerify = ({ children }) => {
  const currentStage = useSelector(({ stage }) => stage.stage.stage);

  if (currentStage !== 'user_verification') {
    return <Navigate to="/register" />;
  }

  return children;
};
