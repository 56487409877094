import styled from 'styled-components';

export const BorderColorBlueStyles = styled.label`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 16px 24px;
  width: 576px;
  height: 142px;
  border-left: 4px solid #004ccc;
  cursor: pointer;

  h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
  }

  @media screen and (max-width: 912px) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 550px) {
    ${'' /* padding: 24px 0px 24px 24px; */}
  }
`;
export const BorderColorDefaultStyles = styled.section`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 16px 24px;
  width: 576px;
  height: 142px;
  border-left: 4px solid #f2f4f7;
  cursor: pointer;

  h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
  }

  @media screen and (max-width: 912px) {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 550px) {
    ${'' /* padding: 24px 0px 24px 24px; */}
  }
`;
export const BorderNoColorStyles = styled.section`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 16px 0;
  width: 576px;
  height: 142px;
  border: 0;

  h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
  }

  @media screen and (max-width: 912px) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 550px) {
    ${'' /* padding: 24px 0px 24px 24px; */}
  }
`;
