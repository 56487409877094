import styled from 'styled-components';

const BenefitCardStyles = styled.section`
  width: 32%;
  height: 330px;
  padding: 3rem 2rem;
  border-radius: 8px;
  background: #f5f9ff;

  header {
    font-weight: 600;
    font-size: 20px;
    line-height: 60px;
    color: #002666;
  }

  .benefit_text {
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #5d6b98;
    }
  }

  @media screen and (max-width: 912px) {
    width: 100%;
    margin-bottom: 2rem;
    height: 340px;
  }

  @media (max-width: 320px) {
    padding: 2rem 1rem;
  }
`;

const SmBenefitStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  width: 31%;
  height: 266px;
  border: 0;
  border-radius: 12px;
  margin-top: 3rem;
  background: ${(props) => props.theme.colors.neutral_30};

  img {
    margin-bottom: 2rem;
  }

  header {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #101828;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    margin-top: 1rem;
  }

  small {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #003999;
    margin-top: 1rem;
  }

  @media screen and (max-width: 912px) {
    margin-top: 5rem;
    width: 100%;

    img {
      margin-bottom: 10px;
    }

    header {
      line-height: 50px;
    }
  }

  @media screen and (max-width: 550px) {
    width: 100%;
    margin-top: 3rem;
  }
`;

export { BenefitCardStyles, SmBenefitStyle };
