import styled from 'styled-components';

const HeroStyles = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.primary_1000};

  .custom_header {
    ${
      '' /* background-image: url(${heroBackground});
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%; */
    }
  }
`;

export { HeroStyles };
