import ReactDom from 'react-dom';
import '../../../index.css';

export default function CustomModal({
  portal,
  open,
  children,
  setClose,
  headerSubText,
  onClose,
  hasHeader,
  centerContent,
  headerText,
  image,
  overFlowShouldNotCloseModal = false,
  showHeader = true,
  takeChildSize
}) {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      {!portal ? (
        <div className="modal_overlay" onClick={() => overFlowShouldNotCloseModal ? null : setClose((prev) => !prev)} />
      ) : (
        <div className="portal_modal_overlay" onClick={() => overFlowShouldNotCloseModal ? null : setClose((prev) => !prev)} />
      )}

      <div className={takeChildSize ? `modal_content_with_child_size` : 'modal_content'}>
        {showHeader && (<div className={!image ? 'modal_header' : 'modal_header_image'}>
          <div className="modal_header_sub">
            {hasHeader &&
              (!image ? (
                <div>
                  <h3>{headerText}</h3>
                </div>
              ) : (
                <div>
                  <img src={image} alt="cumstom-modal-icon" />
                </div>
              ))}
            {centerContent && (
              <div className="modal_center_content">
                <h1>{headerText}</h1>
                <p>{headerSubText}</p>
              </div>
            )}
          </div>

          <span className="close" onClick={() => setClose((prev) => !prev)}>
            &times;
          </span>
        </div>)}

        <main className="modal_body">{children}</main>
      </div>
    </>,
    document.getElementById('portal')
  );
}
