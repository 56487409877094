import React from 'react';
import { ThemeProvider } from "styled-components";

import GlobalStyle from "./globalstyles";

const theme = {
    colors: {
        neutral_900: "#111322",
        neutral_800: "#30374F",
        neutral_700: "#404968",
        neutral_750: "#475467",
        neutral_600: "#4A5578",
        neutral_650: "#98A2B3",
        neutral_500: "#5D6B98",
        neutral_400: " #7D89B0",
        neutral_300: "#B9C0D4",
        neutral_200: "#DCDFEA",
        neutral_100: "#EFF1F5",
        neutral_50: "#F9F9FB",
        neutral_30: "#F9FAFB",
        neutral_25: "#FCFCFD",
        neutral_10: "#EAECF0",
        primary_1000: "#1D2939",
        primary_900: "#162234",
        primary_950: "#101828",
        primary_800: "#002666",
        primary_700: "#003999",
        primary_600: "#004CCC",
        primary_500: "#0C66FF",
        primary_400: "#4388FF",
        primary_300: "#79ABFF",
        primary_200: "#B0CDFF",
        primary_100: "#E6EFFF",
        primary_50: "#F2F7FF",
        secondary_800: "#663200",
        secondary_700: "#994C00",
        secondary_600: "#CC6500",
        secondary_500: "#FF840C",
        secondary_400: "#FFA043",
        secondary_300: "#FFBB79",
        secondary_200: "#FFD7B0",
        secondary_100: "#FFF2E6",
        basic_black: "#000000",
        basic_white: "#FFFFFF",
        lightGreen: "linear-gradient(to bottom, #edffdf 0%, #d4ffcf 100%)",
        blue: "linear-gradient(26.57deg, #002666 8.33%, #003999 91.67%)" 

    },
    fontSizes: {
        dh_text_5xl: "60px",
        dh_text_4xl: "48px",
        dh_text_3xl: "36px",
        dh_text_2xl: "24px",
        dh_text_xl: "20px",
        mh_text_4xl: "36px",
        mh_text_3xl: "24px",
        mh_text_2xl: "20px",
        mh_text_xl: "16px",
        text_lg: "18px",
        text_base: "16px",
        text_sm: "14px",
        text_xs: "12px"
    },
    fonts: ['IBM Plex Sans', "sans-serif"],
}

const Theme = ({ children }) => (
    <ThemeProvider theme ={theme}>
        <GlobalStyle />
        {children}
    </ThemeProvider>
)

export default Theme;