import React from 'react'
import Button from '../Button';
import CustomInput from '../CustomInput/CustomInput';
import { TablePaginationStyle } from './TablePaginationStyle';

const TablePagination = (props) => {
    const { gotoPage, previousPage, nextPage, canPreviousPage, canNextPage, pageIndex, currentPage = 0, pageLength = 1, showPageNumber = false } = props;

    return (
        <TablePaginationStyle>
            <section className='previous_container'>
                {/* <div className='previous_sm'>
                <Button 
                    has_blackborder={true} 
                    handleClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}>
                    {'<<'}
                </Button>
            </div> */}
                <div className='previous_lg'>
                    <Button
                        has_blackborder={true}
                        handleClick={() => previousPage()}
                        disabled={!canPreviousPage}>
                        Previous
                    </Button>
                </div>
            </section>
            <section className='page_container'>
                {showPageNumber ? (<div className='page_input_container'> <p>Page</p>
                    <p>{currentPage} of {pageLength}</p>
                </div>) :
                    (<div className='page_input_container'>
                        <p>Go to page:</p>
                        <div className='page_input'>
                            <CustomInput
                                type="nmber"
                                value={pageIndex + 1}
                                handleChange={e => {
                                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(pageNumber)
                                }}
                            />
                        </div>
                    </div>)
                }

            </section>
            <section className='next_container'>
                <div className='next_main_container'>
                    <div className='next_lg'>
                        <Button
                            has_blackborder={true}
                            handleClick={() => nextPage()}
                            disabled={!canNextPage}>
                            Next
                        </Button>
                    </div>
                    {/* <div className='next_sm'>
                    <Button 
                        has_blackborder={true} 
                        handleClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}>
                        {'>>'}
                    </Button>
                </div> */}

                </div>
            </section>



        </TablePaginationStyle>
    )
}

export default TablePagination