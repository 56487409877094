import { SET_ALLPRODUCT } from '../types';

const initialState = {
  products: []
};

const productsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ALLPRODUCT:
      return {
        ...state,
        products: payload
      };
    default:
      return state;
  }
};

export default productsReducer;
