import styled from 'styled-components';

export const CountryModalStyle = styled.article`
  .countryCards_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    position: sticky;
    top: 100px;
    overflow-y: scroll;
    height: 40vh;
    padding: 2rem 3rem;
    justify-content: space-between;
  }

  .countryCards_container > .countryCards {
    width: 48%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;

    ${'' /* justify-content: center; */}
  }

  .countryCards_img {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      height: 40px;
      object-fit: fill;
      border-radius: 100%;
    }
  }

  .countryCards > .countryCards_text {
    display: block;
    width: 70%;
    font-weight: 400;
    font-size: 14px;
    ${'' /* line-height: 32px; */}
    margin-left: 12px;
  }

  .country_banner {
    height: 124px;
    width: 100%;
    background: #f5f9ff;
    padding: 24px 65px;
  }

  .country_banner > h3 {
    text-align: center;
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
  }

  .country_footer {
    height: 92px;
    width: 100%;
    background: #162234;
    border-radius: 0 0 12px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 3rem;
  }

  .items_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 100%;
  }

  .items_container > .search {
    width: 60%;
  }

  .items_container > .content_btn {
    width: 35%;
  }

  @media screen and (max-width: 550px) {
    .countryCards_container {
      padding: 2rem 1rem;
    }
    .countryCards_container > .countryCards {
      width: 100%;
    }
    .country_banner {
      height: auto;
      padding: 3rem 1rem;
    }

    .items_container {
      flex-direction: column;
    }

    .country_footer {
      height: auto;
      padding: 3rem 1rem;
    }

    .items_container > .search,
    .items_container > .content_btn {
      width: 100%;
    }

    .items_container > .content_btn {
      margin-top: 1rem;
    }
  }
`;
