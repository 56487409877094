import styled from 'styled-components';

const ButtonStyle = styled.button`
  width: 100%;
  height: 48px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  background: #f2f7ff;
  color: #003999;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  &:hover {
    background: #e6efff;
    color: #002666;
  }

  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
  }
`;

const BlueButtonStyle = styled.button`
  width: 100%;
  height: 48px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSizes.text_base};
  background: ${(props) => props.theme.colors.primary_600};
  color: ${(props) => props.theme.colors.basic_white};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #003999;
    border: 1px solid #003999;
  }

  &:focus {
    background: #004ccc;
    border: 1px solid #004ccc;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #cfdefe;
  }

  &:disabled {
    background: #b0cdff;
    border: 1px solid #b0cdff;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;
const HablackborderButtonStyle = styled.button`
  width: 100%;
  height: 48px;
  font-weight: 600;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSizes.text_base};
  background: #ffffff;
  color: #344054;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;

  &:hover {
    background: #e6efff;
    color: #002666;
  }

  &:focus {
    background: #e6efff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #cfdefe;
  }

  /* &:disabled {
    background: #b0cdff;
    border: 1px solid #b0cdff;
  } */

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

const WithBorderColorStyles = styled.button`
  width: 200px;
  height: 48px;
  font-weight: 600;
  border: 1px solid ${(props) => props.theme.colors.primary_300};
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSizes.text_base};
  background: ${(props) => props.theme.colors.basic_white};
  color: #003999;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;

  &:hover {
    background: #f2f7ff;
    border: 0;
  }

  &:focus {
    border: 0;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;
const WithBorderTransparentColorStyles = styled.button`
  width: 100%;
  height: 48px;
  font-weight: 600;
  ${'' /* border: 1px solid ${(props) => props.theme.colors.primary_300}; */}
  border: none;
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSizes.text_base};
  background: transparent;
  color: ${(props) => props.theme.colors.basic_white};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.primary_50};
    border: 0;
    color: ${(props) => props.theme.colors.primary_800};
  }

  &:focus {
    border: 0;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;
const HasNoBorderStyles = styled.button`
  width: 100%;
  height: 48px;
  font-weight: 600;
  ${'' /* border: 1px solid ${(props) => props.theme.colors.primary_300}; */}
  border: none;
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSizes.text_base};
  background: transparent;
  color: #475467;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: ${(props) => props.theme.colors.primary_50};
    border: 0;
    color: ${(props) => props.theme.colors.primary_800};
  }

  &:focus {
    border: 0;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

const RequestStyles = styled.button`
  width: 139px;
  height: 48px;
  font-weight: 600;
  border: 0;
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSizes.text_base};
  background: transparent;
  color: #003999;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.primary_800};
  }

  &:focus {
    border: 0;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

export {
  ButtonStyle,
  BlueButtonStyle,
  RequestStyles,
  HablackborderButtonStyle,
  WithBorderColorStyles,
  WithBorderTransparentColorStyles,
  HasNoBorderStyles
};
