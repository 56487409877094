import React, { useState } from 'react';
import BorderColorCard from '../BorderColorCard/BorderColorCard';
import Title from '../Title/Title';
import { TitlespacedContStyles } from './TitlespacedContStyles';

const TitlespacedContent = ({ title_sm, title_lg, description, data, image, reverse }) => {
  const [customState, setCustomState] = useState({
    image_Id: data[0]?.imageId,
    imageFile: data[0]?.imageFile
  });

  const { imageFile, image_Id } = customState;

  const handleRetrieveImageData = ({ imageId, imageFile }) => {
    setCustomState({
      image_Id: imageId,
      imageFile
    });
  };

  return (
    <TitlespacedContStyles>
      <Title sm text={title_sm} />
      <div className="meta" />
      <Title lg text={title_lg} />
      <br />
      <p className="tspc_pText">{description}</p>

      <main className={reverse ? 'section_card_reversed' : 'section_card'}>
        <section className="tspc_article_scetion">
          {data?.map(({ title, content, imageId, imageFile }, index) => {
            if (image_Id === imageId) {
              return (
                <BorderColorCard
                  key={imageId}
                  title={title}
                  imageId={imageId}
                  imageFile={imageFile}
                  content={content}
                  colorDefault={false}
                  colorBlue={true}
                  handleClick={handleRetrieveImageData}
                />
              );
            }
            return (
              <BorderColorCard
                key={imageId}
                title={title}
                imageId={imageId}
                imageFile={imageFile}
                content={content}
                colorDefault={true}
                colorBlue={false}
                handleClick={handleRetrieveImageData}
              />
            );
          })}
        </section>
        <section className="tspc_image_scetion">
          <div className="tspc_image">
            <img src={imageFile} alt={`${imageFile}`} />
          </div>
        </section>
      </main>
    </TitlespacedContStyles>
  );
};

export default TitlespacedContent;
