import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  AssetVaultIndex,
  AssetVaultPage,
  CheckEmailPage,
  CheckoutPage,
  CreateEmployeePage,
  DashboardPage,
  DeliveryMethodPage,
  EmployeesDetailsPage,
  EmployeesIndexPage,
  EmployeesPage,
  Error401Page,
  ForgetPasswordPage,
  // Homepage,
  LoginPage,
  MarketplaceOrderPage,
  MarketplacePage,
  OnboardingFlowIndexPage,
  OnboardingFlowPage,
  OrdersDetails,
  OrdersHistory,
  OrdersPage,
  OverviewPage,
  PortalPage,
  RegisterInvitedUserPage,
  SelectedItemDetailsPage,
  SelectEmployeePage,
  SetNewPasswordPage,
  SignUpPage,
  SuccessPasswordPage,
  UsersPage,
  VerifyPage,
  OffBoardingPage,
  OffBoardingEmployee,
  HoeMarketPlace,
  MarketplaceHomePage,
  // OffBoardingCreateEmployee,
  // OffBoardingAssets,
  // PickupPage
} from '../pages';
import MainStore from '../pages/portal/MarketPlace/MainStore';
import { ProtectedVerify } from './ProtectedRoutes/ProtectedAuth';
// import { CustomOnboardProtectedRoute, ProtectedPersonalDetails } from './ProtectedRoutes/ProtectedOnboading'
import { CustomPortalProtectedRoute, ProtectedMarketplaceOrder } from './ProtectedRoutes/ProtectedPortalRoutes';
import Notification from '../components/shared/Notification/notification';

const Navigator = () => {
  return (
    <Suspense fallback={'loading...'}>
      <Routes>
        
        <Route exact path="" element={<MarketplaceHomePage />}>
          <Route path="" element={<HoeMarketPlace />} />
        </Route>
        <Route exact path="/register" element={<SignUpPage />} />
        <Route exact path="/register-invited-user" element={<RegisterInvitedUserPage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route
          exact
          path="/verify"
          element={
            <ProtectedVerify>
              <VerifyPage />
            </ProtectedVerify>
          }
        />
        <Route exact path="/forget-password" element={<ForgetPasswordPage />} />
        <Route exact path="/check-email" element={<CheckEmailPage />} />
        <Route exact path="/new-password" element={<SetNewPasswordPage />} />
        <Route exact path="/success-password-state" element={<SuccessPasswordPage />} />
        <Route exact path="/401" element={<Error401Page />} />
        <Route exact path="/notification" element={<Notification />} />

        <Route exact path="onboarding" element={<OnboardingFlowPage />}>
          <Route exact path="" element={<OnboardingFlowIndexPage />} />
        </Route>

        {/* onboading */}
        {/* <Route exact path="onboarding" element={<OnboardingPage />}>
          <Route
            path="profile-details"
            element={<ProtectedPersonalDetails><ProfileDetailsPage /></ProtectedPersonalDetails>}
          />
          <Route
            path="company-details"
            element={<CustomOnboardProtectedRoute><CompanyDetailsPage /></CustomOnboardProtectedRoute>}
          />
          <Route exact path="choose-user" element={<CustomOnboardProtectedRoute><ChooseUserPage /></CustomOnboardProtectedRoute>} />
          <Route exact path="/onboarding" element={<Navigate replace to="/onboarding/profile-details" />} />
        </Route> */}

        {/* Portal */}
        <Route
          exact
          path="portal"
          element={
            <CustomPortalProtectedRoute>
              <PortalPage />
            </CustomPortalProtectedRoute>
          }
        >
          <Route exact path="overview" element={<OverviewPage />}>
            <Route exact path="dashboard" element={<DashboardPage />} />

            <Route exact path="checkout" element={<CheckoutPage />}>
              <Route exact path="select-employee" element={<SelectEmployeePage />} />
              <Route exact path="create-employee" element={<CreateEmployeePage />} />
              <Route exact path="delivery-method/:employee_id" element={<DeliveryMethodPage />} />

              <Route
                exact
                path="/portal/overview/checkout"
                element={<Navigate replace to="/portal/overview/checkout/select-employee" />}
              />
            </Route>

            <Route exact path="/portal/overview" element={<Navigate replace to="/portal/overview/dashboard" />} />
          </Route>

          <Route exact path="offboarding" element={<OffBoardingPage />}>
            <Route exact path="" element={<OffBoardingEmployee />} />
            {/* <Route exact path="create-employee" element={<OffBoardingCreateEmployee />} /> */}
            {/* <Route exact path="assets" element={<OffBoardingAssets />} /> */}
            {/* Update later */}
            {/* <Route exact path="pickups" element={<PickupPage />} /> */}
          </Route>

          <Route exact path="marketplace" element={<MarketplacePage />}>
            <Route exact path="main_store" element={<MainStore />} />
            <Route
              exact
              path="marketplace-order"
              element={
                <ProtectedMarketplaceOrder>
                  <MarketplaceOrderPage />
                </ProtectedMarketplaceOrder>
              }
            />
            <Route exact path="details/:id" element={<SelectedItemDetailsPage />} />
            <Route
              exact
              path="/portal/marketplace"
              element={<Navigate replace to="/portal/marketplace/main_store" />}
            />
          </Route>

          <Route exact path="asset-vault" element={<AssetVaultPage />}>
            <Route exact path="" element={<AssetVaultIndex />} />
          </Route>

          <Route exact path="orders" element={<OrdersPage />}>
            <Route exact path="" element={<OrdersHistory />} />
            <Route exact path=":orderId" element={<OrdersDetails />} />
          </Route>

          <Route exact path="employees" element={<EmployeesPage />}>
            <Route path="" element={<EmployeesIndexPage />} />
            <Route exact path="details/:employee_id" element={<EmployeesDetailsPage />} />
          </Route>

          <Route exact path="users" element={<UsersPage />} />
          <Route exact path="/portal" element={<Navigate replace to="/portal/overview" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Navigator;
