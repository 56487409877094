import NotificationStyle from "./notificationStyle";
import { apple_macbook, phone8, sony_xperia } from "../../../assets/images";
import Button from "../Button";
import CustomPortalModal from "../CustomPortalModal/CustomPortalModal";
import { useContext } from "react";
import { AppContext } from "../../../utils";


const Notification = () => {

    const { openNotification, setOpenNotification } = useContext(AppContext);
    return(
        <CustomPortalModal open={openNotification} setOpen={setOpenNotification} headerText_title={"Notification"}>
            <NotificationStyle>
                {/* <div className="Notification_header">
                    
                    <div>
                        <h3>Notifications</h3>
                       
                    </div>
                </div> */}
                {/* <hr/> */}
                <div className="notificationProducts_section">
                    <div className="notificationProduct_container"> 
                        <div><img src={phone8} alt="phone" /></div>
                        <div className="notificationDetail_container">
                            <div>Phone 8 - 256GB, 4G LTE, Green (Refurbished)</div>
                            <p>Is in transit to <b>James Dada</b></p>

                           
                        </div>
                                          
                    </div>
                </div>
                {/* <hr /> */}

                <div className="notificationProducts_section">
                    <div className="notificationProduct_container"> 
                        <div><img src={apple_macbook} alt="phone" /></div>
                        <div className="notificationDetail_container">
                            <div>Phone 8 - 256GB, 4G LTE, Green (Refurbished)</div>
                            <p>Is in transit to <b>James Dada</b></p>

                           
                        </div>
                                          
                    </div>
                </div>
                {/* <hr /> */}
                <div className="notificationProducts_section">
                    <div className="notificationProduct_container"> 
                        <div><img src={sony_xperia} alt="phone" /></div>
                        <div className="notificationDetail_container">
                            <div>Phone 8 - 256GB, 4G LTE, Green (Refurbished)</div>
                            <p>Is in transit to <b>James Dada</b></p>

                           
                        </div>
                                          
                    </div>
                </div>
                
                <div className="notificationProducts_section">
                    <div className="notificationProduct_container"> 
                        <div><img src={apple_macbook} alt="phone" /></div>
                        <div className="notificationDetail_container">
                            <div>Phone 8 - 256GB, 4G LTE, Green (Refurbished)</div>
                            <p>Is in transit to <b>James Dada</b></p>

                           
                        </div>
                                          
                    </div>
                </div>

                <div className="notificationProducts_section">
                    <div className="notificationProduct_container"> 
                        <div><img src={sony_xperia} alt="phone" /></div>
                        <div className="notificationDetail_container">
                            <div>Phone 8 - 256GB, 4G LTE, Green (Refurbished)</div>
                            <p>Is in transit to <b>James Dada</b></p>

                           
                        </div>
                                          
                    </div>
                </div>
                <div className="notification_button">
                    <Button bg_default={true}>Cancel</Button>
                </div>      
            </NotificationStyle>
        </CustomPortalModal>
    )
}

export default Notification;