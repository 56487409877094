import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar';
import Select, {components} from 'react-select';
import { CustomSelectStyle } from './CustomSelect';

const CustomSelect = ({optionData, defaultStructure, disabled, defaultValue, select_by, select_by_2, label, withImage, isDefault, placeholder, handleChange, handleBlur, children}) => {
    const GRAVATAR_SIZE = 30;
    const [data, setData] = useState([]);

    useEffect(() => {
      if (withImage) {
        const getData = optionData?.map((item) => ({label: item.name, value: item.name, avatar: item.flag }));
        setData(getData)
      }

      if (isDefault && !defaultStructure) {
        const getData = optionData?.map((item) => {
          if(typeof item === 'object' && item !== null){
            return {label: item.name, value: item.name }
          } 
          return {label: item, value: item }
        });
        setData(getData)
      }

      if (isDefault && defaultStructure && select_by && select_by_2) {
        const getData = optionData?.map((item) => {
          
          if(typeof item === 'object' && item !== null){
            return {label: `${item[select_by]} ${item[select_by_2]}`, value: !item.id ? item[select_by] : item.id }
          } 

          return {label: item, value: item }
        });
        setData(getData)
      }

      if (isDefault && defaultStructure && select_by && !select_by_2) {
        const getData = optionData?.map((item) => {
          if(typeof item === 'object' && item !== null){
            return {label: item[select_by], value: !item.id ? item[select_by] : item.id }
          } 

          return {label: item, value: item }
        });
        setData(getData)
      }
      
      

    }, [optionData])

    const Option = props => {
        const {data} = props;
        return (
          <components.Option {...props}>
            <Avatar round size={GRAVATAR_SIZE} style={{marginRight: "10px"}} className="avatar" color="#0366d6" name={data.label} src={data.avatar} />
            {data.label}
          </components.Option>
        );
    };

    const colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      
      // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //   return {
      //     ...styles,
          
      //     color: isDisabled
      //     ? '#ccc'
      //     : isSelected
      //     ? '#101828'
      //     : '#101828',

      //   };
      // },
      // input: (styles) => ({ ...styles }) => {
      //   return {
      //     ...styles,
      //     padding: '22px',
      //   }
      // },
      // placeholder: (styles) => ({ ...styles }) => {
      //   return {
      //     ...styles,
      //     marginTop: '12px'
      //   }
      // },
      // singleValue: (styles, { data }) => ({ ...styles }),
    };

  return (
    <CustomSelectStyle>
      {
        label && (
          <label className="form_label">
            {label}{' '}
          </label>
        )
      }
      
      {
        withImage && (
          <Select
            styles={colourStyles} 
            components={{ Option }}
            options={data}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        )
      }
      {
        isDefault && (
          <Select 
            styles={colourStyles}
            options={data}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={defaultValue}
            isDisabled={disabled}
          />
        )
      }

      {
        children
      }
    </CustomSelectStyle>
    
  )
}

export default CustomSelect