import React from 'react'
import { FaCaretDown } from "react-icons/fa";
import { CheckboxDropdownStyles } from './CheckboxDropdownStyles'

const CheckboxDropdown = ({children, title}) => {
  return (
    <CheckboxDropdownStyles>
        <section className='cbds_header'>
            <h3>{title}</h3>
            <FaCaretDown size={22}/>
        </section>
        <section className={children?.length < 7 ? 'cbds_content' : 'cbds_content add_sticky'}>
            {children}
        </section>
    </CheckboxDropdownStyles>
  )
}

export default CheckboxDropdown