import styled from 'styled-components';

export const TableLayoutStyle = styled.table`
  border-collapse: collapse;
  border-top: 1px solid #eaecf0;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  background: transparent;

  thead {
    padding: 12px 15px;
    font-weight: 500;
  }

  thead tr {
    background: transparent;
    text-align: left;
  }

  th,
  td {
    padding: 12px 15px;
  }

  tbody {
    overflow-x: auto;
  }

  tbody tr {
    border-bottom: 1px solid #eaecf0;
  }

  tbody tr:nth-of-type(even) {
    background-color: #f9fafb;
  }

  tbody tr:last-of-type {
    border-bottom: 2px solid #eaecf0;
  }

  tbody tr:first-of-type {
    border-top: 1px solid #eaecf0;
  }
`;
