const footerdata = [
  {
    id: 1,
    name: 'Onboarding',
    path: '#onboarding'
  },
  {
    id: 2,
    name: 'Off-boarding',
    path: '#off-boarding'
  },
  {
    id: 3,
    name: 'Partnerships',
    path: '#partnerships'
  },
  {
    id: 4,
    name: 'FAQs',
    path: '#faqs'
  },
  {
    id: 5,
    name: 'Contact Us',
    path: '#contact-us'
  }
];

export { footerdata };
