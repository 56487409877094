export let baseURL =
  process.env.REACT_APP_ENV === 'local'
    ? process.env.REACT_APP_LOCAL_URL
    : process.env.REACT_APP_ENV === 'staging'
      ? process.env.REACT_APP_STAGING_URL
      : process.env.REACT_APP_ENV === 'production'
        ? process.env.REACT_APP_PRODUCTION_URL
        : '';
export const setToken = (value) => localStorage.setItem('remote-token', value);
export const setActiveOnboardingStage = (value) =>
  localStorage.setItem('remote-active-onboarding-stage', JSON.parse(value));
export const setTransactionStatus = (value) => localStorage.setItem('remote-tx_status', value);
export const setTransactionRef = (value) => localStorage.setItem('remote-tx_ref', value);
export const setCompanyId = (value) => localStorage.setItem('remote-companyId', value);
export const setUserEmail = (value) => localStorage.setItem('remote-userEmail', value);
export const setDetailsId = (value) => localStorage.setItem('remote-detailsId', value);
export const setPresetEmployee = (value) => localStorage.setItem('remote-preset-employee', JSON.stringify(value));
export const setUserId = (value) => localStorage.setItem('remote-userId', JSON.parse(value));
export const setSelectedItem = (value) => localStorage.setItem('remote-selectedItem', JSON.parse(value));
export const setUserData = (value) => localStorage.setItem('remote-userData', JSON.stringify(value));
export const setDashboardData = (value) => localStorage.setItem('remote-dashboardData', JSON.stringify(value));

export const getToken = () => localStorage.getItem('remote-token');
export const getActiveOnboardingStage = () => localStorage.getItem('remote-active-onboarding-stage');
export const getTransactionStatus = () => localStorage.getItem('remote-tx_status');
export const getTransactionRef = () => localStorage.getItem('remote-tx_ref');
export const getCompanyId = () => localStorage.getItem('remote-companyId');
export const getUserId = () => localStorage.getItem('remote-userId');
export const getSelectedItem = () => localStorage.getItem('remote-selectedItem');
export const getUserData = () => localStorage.getItem('remote-userData');
export const getDetailsId = () => localStorage.getItem('remote-detailsId');
export const getUserEmail = () => localStorage.getItem('remote-userEmail');
export const getPresetEmployee = () => localStorage.getItem('remote-preset-employee');

export const getDashboardData = () => localStorage.getItem('remote-dashboardData');

export const deleteToken = () => localStorage.removeItem('remote-token');
export const deletePresetEmployee = () => localStorage.removeItem('remote-preset-employee');
export const deleteActiveOnboardingStage = () => localStorage.removeItem('remote-active-onboarding-stage');

export const handleCurrencySymbolGeneartion = (iso, amount) => {
  if (iso === 'NGN' || iso === 'ngn') return '₦' + parseInt(amount)?.toLocaleString('en-us');

  const currInstance = new Intl.NumberFormat('en-uk', { style: 'currency', currency: iso })

  return currInstance.formatToParts(amount).map(val => val.value).join('');
}