import styled from 'styled-components';

export const ProductCardStyles = styled.article`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 220px;
  /* height: 330px; */
  background: #ffffff;
  border: ${(props) => (props.isSelected ? `1px solid #004CCC` : `1px solid #eaecf0`)};
  border-radius: 16px;
  cursor: pointer;

  .card__cover {
    border-radius: 16px;
    height: 156px;

    background: #f2f4f7;
    display: flex;
    flex-direction: column;

    div {
      width: 6.25rem;
      height: auto;
      margin: auto;

      img {
        width: 100%;
        object-fit: fill;
      }
    }
  }

  .card__content {
    margin-top: 12px;

    h3,
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    h3,
    label {
      color: #98a2b3;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      /* text-decoration-line: underline; */
      color: #101828;
      margin-top: 6px;
    }

    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
    }
  }

  @media (min-width: 1281px) {
    width: 244.33px;
  }

  @media (min-width: 913px) and (max-width: 1024px) {
    width: 270px;
  }

  @media screen and (max-width: 912px) {
    width: 244.33px;
  }

  @media screen and (max-width: 768px) {
    width: 230px;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
    height: auto;
  }
`;

export const ProductCardLGStyles = styled.article`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 270px;
  /* height: 360px; */
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 16px;
  cursor: pointer;

  .card__cover {
    border-radius: 16px;
    height: 180px;
    background: #f2f4f7;
    display: flex;
    flex-direction: column;

    div {
      width: 11.25rem;
      height: auto;
      margin: auto;

      img {
        width: 100%;
        object-fit: fill;
      }
    }
  }

  .card__content {
    margin-top: 12px;

    h3,
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    h3,
    label {
      color: #98a2b3;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      /* text-decoration-line: underline; */
      color: #101828;
      margin-top: 6px;
    }

    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
    }
  }

  @media (min-width: 1281px) {
    height: 400px;

    .card__cover {
      border-radius: 16px;
      height: 220px;
    }
  }

  @media (min-width: 913px) and (max-width: 1024px) {
    width: 270px;
  }

  @media screen and (max-width: 912px) {
    width: 244.33px;
  }

  @media screen and (max-width: 768px) {
    width: 230px;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
    height: auto;
  }
`;
