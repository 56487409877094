import { partnering_progress_image } from '../../../assets/images';
import { gotoRoute } from '../../../utils';
import { Button, SectionCard } from '../../shared';
import { PartneringProgressStyles } from './PartneringProgressStyles';

const PartneringProgress = () => {
  return (
    <PartneringProgressStyles>
      <SectionCard image={partnering_progress_image}>
        <h1 className="h1_text">Partnering for Progress</h1>
        <p className="p_text">
          Great talents deserve the right tools to do great work without geographical limitations. Our partnerships
          ensure that and more.
        </p>

        <div className="content_btn">
          <Button
            bg_blue={true}
            handleClick={() => gotoRoute('https://calendly.com/abisoye-fadare/partner-onboarding-brief')}
          >
            Partner With Us
          </Button>
        </div>
      </SectionCard>
    </PartneringProgressStyles>
  );
};

export default PartneringProgress;
