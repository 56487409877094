import styled from "styled-components";

const NotificationStyle = styled.article`

    max-width: 100%;
    margin: 30px;
    box-sizing: border-box;
    line-height: 1.5;
    color: #101828;

.Notification_container hr{
    border: 1px solid #EAECF0;
}
.Notification_header{
    display: flex;
    margin: 20px 0px;
    /* justify-content: baseline; */
}
.Notification_header p{
    color: #475467;
    font-size: 14px;
}
.Notification_header > div{
    padding-right: 30px;
}
.Notification_header_buttonContainer button{
    font-size: 30px;
    padding: 5px 10px;
    border: 1px solid #EAECF0;
    border-radius: 10px;
    background-color: #FFFFFF;
}
.notificationProducts_section{
    padding: 10px 0;
    border-bottom: 1px solid #EAECF0;
}
.notificationProduct_container{
    display: flex;
    justify-content: space-between;
    line-height: 2;

    div{
        color: #344054;
    }
    p{
        color:#475467
    }
    b{
        color:#003999
    }

    img{
        border-radius: 100%
    }
}


.notification_button > Button{
    text-align: center;
    background-color: #FFFFFF;
    color: #344054;
    border: 1px solid #D0D5DD;
    font-size: 18px;    
}

`
export default NotificationStyle;