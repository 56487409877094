import React from 'react';
import { Title } from '../../shared';
import { ImpactStyles } from './ImpactStyles';

const ImpactBusiness = () => {
  return (
    <ImpactStyles>
      <Title lg center text={'Our Impact on Your Business'} />
      <div className="meta" />
      <p className="ib_subtitle">See how our services can benefit your company</p>

      <div className="ib_content">
        <div className="ib_content_container">
          {[
            {
              title: '50%',
              text: `Shorter onboarding process`
            },
            {
              title: '30%',
              text: `Increase in employee productivity`
            },
            {
              title: '25%',
              text: `Reduction in IT asset costs`
            }
          ].map(({ title, text }, index) => (
            <div key={index} className="ib_content_card">
              <h1>{title}</h1>
              <p>{text}</p>
            </div>
          ))}
        </div>
      </div>
    </ImpactStyles>
  );
};

export default ImpactBusiness;
