import React, { useState } from 'react';
import { FaqStyles } from './faqStyles';
import { questionData } from './questionData';
import { Accordion, Button, TextSm, Title } from '../../shared';
import { group_faq_avatar } from '../../../assets/images';
import { gotoRoute } from '../../../utils';

const Faq = () => {
  const [questions] = useState(questionData);
  return (
    <FaqStyles id="#faqs">
      <section className="content_header">
        <Title lg center text={'Frequently asked questions'}></Title>
        <div className="text_sm">
          <TextSm>
            Onboarding, Off-boarding, and General Information about Rayda Remote's IT Asset Provisioning Services
          </TextSm>
        </div>
      </section>

      <section className="content_body">
        {questions.map((question) => {
          return <Accordion key={question.id} {...question}></Accordion>;
        })}
      </section>

      <section className="content_footer">
        <div className="content_footer_container">
          <picture className="faq_avatar">
            <img src={group_faq_avatar} alt="" />
          </picture>

          <Title md center text={'Still have questions?'}></Title>
          <div className="contact_text_sm">
            <TextSm>Can’t find the answer you’re looking for? Please chat to our friendly team.</TextSm>
          </div>

          <div className="content_btn">
            <Button
              bg_blue={true}
              handleClick={() => gotoRoute('https://calendly.com/abisoye-fadare/partner-onboarding-brief')}
            >
              Get in touch
            </Button>
          </div>
        </div>
      </section>
    </FaqStyles>
  );
};

export default Faq;
