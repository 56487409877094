import { getToken } from '../helper';
import api from '../interceptor';
const token = getToken();

export const requestDashboardData = async (company_id) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/dashboard`);
};

export const requestAvaliableProduct = async (company_id, enableQuery) => {
  if (!enableQuery) {
    return await api.get(`/api/v1/portal/companies/${company_id}/assets`);
  }
  return await api.get(`/api/v1/portal/companies/${company_id}/assets?${enableQuery}`);
};

export const requestAvaliableProductForPublic = async (currency, page_no, enableQuery) => {
  return await api.get(`/api/v1/portal/assets?${enableQuery? `${enableQuery}&`: ''}country=${currency}&page=${page_no}`);
};

export const requestSingleProduct = async (company_id, id) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/assets/${id}`);
};

export const requestAddCart = async (company_id, formData) => {
  return await api.post(`/api/v1/portal/companies/${company_id}/carts`, formData);
};

export const requestFetchCart = async (company_id) => {
  if (!token) {
    return null;
  }
  return await api.get(`/api/v1/portal/companies/${company_id}/carts`);
};

export const requestUpdateCart = async (company_id, cart_id, formData) => {
  return await api.put(`/api/v1/portal/companies/${company_id}/carts/${cart_id}`, formData);
};

export const requestDeleteCart = async (company_id, cart_id, formData) => {
  return await api.patch(`/api/v1/portal/companies/${company_id}/carts/${cart_id}`, formData);
};

export const requestClearCart = async (company_id, cart_id, user_id) => {
  return await api.delete(`/api/v1/portal/companies/${company_id}/carts/${cart_id}`, { user_id });
};

export const requestAllEmployee = async (company_id) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/employees`);
};

export const requestSingleEmployee = async (company_id, employee_id) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/employees/${employee_id}`);
};

export const requestCreateEmployee = async (company_id, formData) => {
  return await api.post(`/api/v1/portal/companies/${company_id}/employees`, formData);
};

export const requestEditEmployee = async (company_id, employee_id, formData) => {
  return await api.put(`/api/v1/portal/companies/${company_id}/employees/${employee_id}/profile`, formData);
};

export const requestEditShippingDetails = async (company_id, employee_id, formData) => {
  return await api.put(`/api/v1/portal/companies/${company_id}/employees/${employee_id}/delivery-details`, formData);
};

export const requestDeleteEmployee = async (company_id, employee_id) => {
  return await api.delete(`/api/v1/portal/companies/${company_id}/employees/${employee_id}`);
};

export const requestBuy = async (company_id, cart_id, formData) => {
  return await api.post(`/api/v1/portal/companies/${company_id}/carts/${cart_id}/buy`, formData);
};

export const requestOrderBuy = async (company_id, order_id, formData) => {
  return await api.post(`/api/v1/portal/companies/${company_id}/orders/${order_id}/checkout`, formData);
};

export const requestShippingOptions = async (company_id) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/orders/shipping/options`);
};

export const requestSingleOrder = async (company_id, transaction_reference) => {
  // return await api.get(`/api/v1/portal/companies/${company_id}/orders/${transaction_reference}`);
  return await api.get(`/api/v1/payments/${transaction_reference}`);
};

export const requestFetchAssetVault = async (company_id, type) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/assets/vault${type.length ? `?type=${type}` : ''}`);
};

export const requestFetchAssetCategories = async (company_id) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/assets/categories`);
};

export const requestPublicFetchAssetCategories = async () => {
  return await api.get(`/api/v1/portal/assets/categories`);
};

export const requestPersonnelInvitation = async (company_id, formData) => {
  return await api.post(`api/v1/portal/companies/${company_id}/invitations/send`, formData);
};

export const requestAllPersonnel = async (company_id) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/invitations`);
};

export const requestSinglePersonnel = async (company_id, personnel_id) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/invitations/${personnel_id}`);
};

export const requestAllOrderHistory = async (company_id, type, category) => {
  return await api.get(
    `/api/v1/portal/companies/${company_id}/orders?${type ? `order_status=${type}` : ''}${category ? `&order_category=${category}` : ''
    }`
  );
};

export const requestOrderDetail = async (company_id, orderId) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/orders/${orderId}`);
};

export const requestEditPersonnelDetails = async (company_id, personnel_id, formData) => {
  return await api.put(`/api/v1/portal/companies/${company_id}/invitations/${personnel_id}`, formData);
};

export const requestResendPersonnelInvitation = async (company_id, personnel_id) => {
  return await api.put(`/api/v1/portal/companies/${company_id}/invitations/${personnel_id}/resend`);
};

export const requestDeletePersonnelInvitation = async (company_id, personnel_id) => {
  return await api.delete(`/api/v1/portal/companies/${company_id}/invitations/${personnel_id}`);
};

export const requestCreateAsset = async (company_id, formData) => {
  return await api.post(`/api/v1/portal/companies/${company_id}/assets`, formData);
};

export const requestFetchAsset = async (company_id, employee_id) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/employees/${employee_id}`);
};

export const requestFetchABanks = async (company_id) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/banks`);
};

export const requestFetchAccountInfo = async (company_id, bank, account_number) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/banks/${bank}/account?account_number=${account_number}`);
};

export const requestFetchOrderAssets = async (company_id, trans_ref) => {
  return await api.get(`/api/v1/portal/companies/${company_id}/orders/${trans_ref}`);
};

export const requestSaveAccountInfo = async (company_id, bank_info) => {
  return await api.post(`/api/v1/portal/companies/${company_id}/banks/bank-details`, bank_info);
};

export const requestSaveDeliveryAddress = async (company_id, orderId, deliveryInfo) => {
  return await api.put(`/api/v1/portal/companies/${company_id}/orders/${orderId}/delivery-details`, deliveryInfo);
};

export const requestOffBoardAsset = async (company_id, payload) => {
  return await api.post(`/api/v1/portal/companies/${company_id}/assets/offboard`, payload);
};
