import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import App from './App';
import reportWebVitals from './reportWebVitals';
import persistStore from 'redux-persist/es/persistStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Logtail } from '@logtail/browser';
import Theme from './styles/Theme';
import store from './stateManagement/redux/store';
import './index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_PUBLIC_KEY,
  environment: process.env.REACT_APP_ENV,
  beforeBreadcrumb(breadcrumb, hint) {
    return breadcrumb.category === 'ui.click' ? null : breadcrumb;
  }
});
let persistor = persistStore(store);
const logtail = new Logtail('2xx9mnCc8WAXUfDbPDzmK8H8');

const mutationCache = new MutationCache({
  onError: (error, _variables, _context, mutation) => {
    // If this mutation has an onError defined, skip this
    if (mutation?.options?.onError) return;
    // any error handling code...
    toast.error(`Something went wrong: ${error.message}`);
    // Send logs to Logtail
    logtail?.error(error.message);
  }
});

const queryCache = new QueryCache({
  onError: (error, _variables, _context, query) => {
    if (query?.options?.onError) return;
    toast.error(`Something went wrong: ${error.message}`);
    // Send logs to Logtail
    logtail?.error(error.message);
  }
});

// Ensure that all logs are sent to Logtail
logtail.flush();

const queryClient = new QueryClient({ mutationCache, queryCache });

// const root = ReactDOM.createRoot(document.getElementById('root'));
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Theme>
              <App />
            </Theme>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
