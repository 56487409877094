import React from 'react'
import { arrow_up } from '../../../assets/icons'
import { OverviewCardStyles } from './OverviewCardStyles'

const OverviewCard = ({image, title, count, amount}) => {
  return (
    <OverviewCardStyles>
        <img className='img' src={image} alt="overview_icon" />
        
        <p className='overview_p'>{title}</p>

        <div className='sub_section'>
            <h1>{count}</h1>
            {
                title === "Employees" ? (
                    <div className='icon_text'>
                        <img src={arrow_up} alt="arrow_up" />
                        <p className='p_tag_green'>{amount}</p>
                    </div>
                    
                ) : (
                    <p className='p_tag'>{amount}</p>
                )
            }
            
        </div>
        
    </OverviewCardStyles>
  )
}

export default OverviewCard