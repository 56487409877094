// import { useRef } from 'react';
import { EmployeeTypeStyle } from './EmployeeTypeStyle';

const EmployeeType = (props) => {
    const {
        id,
        name,
        value,
        handleCheckedElement,
        card_img,
    } = props;

  return (
    <EmployeeTypeStyle>
        <input
            type="radio"
            name={name}
            value={value}
            id={id}
            onChange={handleCheckedElement}
            
        />
        
        <span className="radio-btn">
            <div className="overlay"/>
            <div className="hobbies-icon">
                <img src={card_img} alt="card_img" />
            </div>
        </span>
    </EmployeeTypeStyle>
  )
}

export default EmployeeType