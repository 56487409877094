import styled from 'styled-components';

export const PartneringCompStyles = styled.article`
  padding: 0 6rem;

  .partner_container {
    border: 2px solid #eee;
    overflow: hidden;
    height: 250px;
    background: ${(props) => props.theme.colors.neutral_50};
  }

  .pc_title_txt {
    margin-top: 64px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: ${(props) => props.theme.colors.neutral_750};
  }

  .animated_container {
    width: inherit;
    ${'' /* overflow: hidden; */}
    box-sizing: border-box;
    position: relative;
  }

  .animated_container > .inner_container {
    display: block;
    ${'' /* width: 200%; */}

    position: absolute;
    animation: animated_container 20s linear infinite;
  }

  .inner_container:hover {
    animation-play-state: paused;
  }

  .animated_container > .inner_container > .inner_content,
  .animated_container > .inner_container > .inner_content > span {
    display: flex;
    align-items: center;
  }

  .animated_image {
    width: 160px;
    margin: 0 24.7px;
    transition: all 0.2s ease-out;
    display: inline-block;

    img {
      width: 100%;
      object-fit: fill;
    }
  }

  @keyframes animated_container {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }

  @media screen and (max-width: 912px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 550px) {
    padding: 0 1rem;
  }
`;
