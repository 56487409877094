import React from 'react';
import { MainHeader } from '../../components/constant';
import { HeroStyles } from './heroStyles';

const HeroLayout = ({ children }) => {
  return (
    <HeroStyles>
      <div className="custom_header">
        <MainHeader />
      </div>

      <main className="hero_content">{children}</main>
    </HeroStyles>
  );
};

export default HeroLayout;
