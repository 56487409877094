import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { AppContext } from '../../../utils';
import CustomModal from '../../shared/CustomModal/CustomModal';
import { Button, CustomInput, CustomLoader, CustomSelect } from '../../shared';
import { user_rounded_icon } from '../../../assets/icons';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { editPersonnelAction, singlePersonnelAction } from '../../../stateManagement/reactQuery/actions/portalActions';
import { EditUserModelStyle } from './EditUserModelStyle';

const EditUserModel = ({personnel_id, company_id}) => {
    const queryClient = useQueryClient();


    const { openEditUser, setOpenEditUser } = useContext(AppContext);
    const [isDisAbled, setIsDisAbled] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [retrieveData, setRetrieveData] = useState({});

    const {mutateAsync: fetchSinglePersonnel } = useMutation((variables) => singlePersonnelAction(variables),
        {
        onSuccess: (response) => {
            const { data : {status, data} } = response;
            

            if(status === "success"){
            setSpinner(false);
            setRetrieveData(data);
            }
        },

        onError: (error) => {
            toast.error(`Something went wrong: ${error.message}`)
        }
            
        }
    );

    const {mutateAsync: handleEditPersonnel } = useMutation((variables) => editPersonnelAction(variables),
    {
      onSuccess: (response) => {
        const { data : {status} } = response;
        

        if(status === "success"){
          setSpinner(false);
          toast.success(`Successfully updated `);

          queryClient.invalidateQueries('all_personnel');
          setOpenEditUser((prev) => !prev)
        }
      },

      onError: (error) => {
        toast.error(`Something went wrong: ${error.message}`)
      }
          
    }
    );


    useEffect(() => {
        (async() => {
            if(personnel_id && company_id){
                setSpinner(true);
                await fetchSinglePersonnel({company_id, personnel_id});
                setSpinner(false);
            }
        })();
    }, [personnel_id])

    const formik = useFormik({
		initialValues: {
            email: "",
			role: ""
		},

		validationSchema: Yup.object().shape({
			email: Yup.string().email()
			.required("Email address is required"),
            role: Yup.string()
			.required("Role is required"),
		}),

		onSubmit: async(values)  => {
            try {
                setSpinner(true);

                const formData = {
                    role: values.role,
                    email: values.email
                }

                await handleEditPersonnel({company_id, personnel_id, formData})

            } catch (error) {
                setSpinner(false)
            }finally {
                setSpinner(false)
            }
        },
    })

    useEffect(() => {

        if(formik.values.email !== ""  
          && formik.values.role !== "" ) {
            setIsDisAbled(false)
          }else {
            setIsDisAbled(true)
        }

      }, [
        formik.values.email,
        formik.values.role
      ])

      useEffect(() => {
        if(retrieveData){
            formik.setFieldValue("email", retrieveData.email);
            formik.setFieldValue("role", retrieveData.role);
        }
      }, [retrieveData])
      
  return (
    <CustomModal 
            open={openEditUser} 
            portal
            hasHeader
            centerContent 
            setClose={setOpenEditUser} 
            image={user_rounded_icon}
            headerText={"User Invite"}
            headerSubText={"Edit user input field to update user invite."}
        >
        {
            !spinner && retrieveData ? (
                <EditUserModelStyle onSubmit={formik.handleSubmit}>
                    <div className='form_container'>
                    <CustomInput 
                        label={"Email"}
                        name="email"
                        type="email"
                        placeholder={"Uzodima Chinonzo@leads.com"}
                        value={formik.values.email}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                    >
                        {formik.touched.email && formik.errors.email ? <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.email}</div> : null}
                    </CustomInput>
                    
                    <CustomSelect
                        label={"Role"}
                        optionData={['Admin', 'Read-only']}
                        isDefault
                        defaultValue={[{label: `${retrieveData?.role}`, value: `${retrieveData?.role}`}]}
                        placeholder={"Select employee type"}
                        handleChange={async({ value }) => formik.setFieldValue("role", value)}
                        handleBlur={formik.handleBlur}
                    />
                    <div className='meta'/>
                        {
                            !spinner ? (
                                <div className='ce_btn_container'>
                                    <div className='btn_container'>
                                        <div className='btn'>
                                            <Button
                                                bg_default={true}
                                                type={"button"}
                                                handleClick={() => setOpenEditUser((prev) => !prev)}
                                            >
                                            Cancel
                                            </Button>
                                        </div>
                                        <div className='btn'>
                                            <Button
                                                bg_blue={true}
                                                type={"submit"}
                                                disabled={isDisAbled}
                                            >
                                                Save Changes
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                            <div style={{margin: "auto"}}>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <CustomLoader/>
                                </div>
                            </div>
                            )

                        }
                    </div>

            
                </EditUserModelStyle>
            ) : (
                <div style={{margin: "auto"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <CustomLoader/>
                    </div>
                </div>
            )
        }
  

    </CustomModal>
  )
}

export default EditUserModel;