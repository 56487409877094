import styled from 'styled-components';

export const PartnershipStyles = styled.section`
  height: 650px;
  padding: 0 4rem 0 3rem;

  .p_text {
    width: 576px;
  }

  .meta {
    margin-top: 12px;
  }

  .content_btn {
    margin-top: 20px;
    width: 153px;
  }

  .section_line {
    background: ${(props) => props.theme.colors.neutral_10};
    width: 530px;
    height: 1px;
    margin: 3rem 0 2rem 0;
  }

  @media (min-width: 1281px) {
    padding: 0 4rem 0 6rem;
  }

  @media screen and (max-width: 912px) {
    padding: 2rem;
    height: auto;

    .p_text,
    .section_line {
      width: 100%;
    }
  }
  @media screen and (max-width: 550px) {
    padding: 2rem 1rem;
  }
`;
