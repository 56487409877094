import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: null,
  activeSelectedEmployee: null,
  orderId: null,
  orderTransRef: null,
  wipeRepair: {
    dataWipe: false,
    repairGadget: false
  }
};

export const offboardingSlice = createSlice({
  name: 'offBoarding',
  initialState,
  reducers: {
    setActiveOffboardingStage: (state, action) => {
      state.step = action.payload;
    },
    setActiveOffboardingEmployeeID: (state, action) => {
      state.activeSelectedEmployee = action.payload;
    },
    setActiveOffboardingOrderID: (state, action) => {
      state.orderId = action.payload;
    },
    setActiveOffboardingOrderTransRef: (state, action) => {
      state.orderTransRef = action.payload;
    },
    setActiveOffboardingWipeRepair: (state, action) => {
      state.wipeRepair = {
        dataWipe: action.payload?.dataWipe,
        repairGadget: action.payload?.repairGadget
      }
    },
    removeOffboardingData: (state) => {
      state.step = null;
      state.activeSelectedEmployee = null;
      state.orderId = null;
      state.orderTransRef = null;
      state.wipeRepair = {
        dataWipe: null,
        repairGadget: null
      }
    }
  }
});

export const { setActiveOffboardingStage, setActiveOffboardingOrderTransRef, setActiveOffboardingEmployeeID, setActiveOffboardingWipeRepair, removeOffboardingData, setActiveOffboardingOrderID } = offboardingSlice.actions;

export default offboardingSlice.reducer;
