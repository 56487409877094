import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import stageReducer from './stageReducer';
import offboardingReducer from '../slice/offboardingSlice';
import productsReducer from './productsReducer';

const rootReducers = combineReducers({
  errors: errorReducer,
  stage: stageReducer,
  offboarding: offboardingReducer,
  products: productsReducer
});

export default rootReducers;
