import axios from 'axios';
import { baseURL, getToken } from './helper';

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error?.response?.status === 401) {
    //   deleteToken();

    //   const { pathname } = window.location;
    //   window.location.href = `/401?redirect=${pathname}`;
    // }
    return Promise.reject(error);
  }
);
export default api;
