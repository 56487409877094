import styled from 'styled-components';

export const ImageCTStyles = styled.article`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  align-items: center;

  .image_section {
    width: 60%;
  }

  .image_container {
    width: 100%;

    .img_layout {
      width: 100%;
      object-fit: cover;
      ${'' /* height: 67vmax; */}
    }
  }

  .content_container {
    width: 40%;
    display: flex;
    background: #ffffff;
    padding: 2rem 3rem 0 3rem;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 0px;
    }
    ${
      '' /* overflow-y: scroll;
    scrollbar-width: none !important; */
    }
  }

  @media (min-width: 1281px) {
    .image_container > .img_layout {
      width: 100%;
      object-fit: cover;
      height: 100vh;
    }
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    .image_container > .img_layout {
      width: 100%;
      object-fit: cover;
      ${'' /* height: 80vmax; */}
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;

    .image_section {
      display: none;
    }

    .content_container {
      width: 100%;
      height: 100vmax;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .image_section {
      display: none;
    }

    .content_container {
      width: 100%;
      height: 110vmax;
    }
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .image_section {
      display: none;
    }

    .content_container {
      width: 100%;
      height: 110vmax;
      padding: 2rem 2rem 0 2rem;
    }
  }

  @media (max-width: 320px) {
    .image_section {
      display: none;
    }

    .content_container {
      width: 100%;
      height: 110vmax;
      padding: 2rem 1rem 0 1rem;
    }
  }
`;
