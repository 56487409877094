import styled from 'styled-components';

export const StepperInputStyles = styled.div`
  display: flex;
  ${'' /* height: 60px; */}

  .stepper-input__button {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 44px;
    height: 44px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    color: #344054;
  }

  .sm_stepper-input__button {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    align-items: center;
    display: flex;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .stepper-input__input {
    border: none;
    text-align: center;
    letter-spacing: -0.02em;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    color: #101828;
    /* Take full size of its container */
    width: 55px;
    height: 44px;
  }

  .sm_stepper-input__input {
    border: none;
    text-align: center;
    /* Take full size of its container */
    width: 24px;
    height: 24px;
  }
`;
