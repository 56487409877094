import React from 'react'
import { lazy_loader } from '../../../assets/images'
import SliceText from '../../../utils/helpers/SliceText'
import toCurrency from '../../../utils/helpers/toCurrency'
import { ProductCardLGStyles, ProductCardStyles } from './ProductCardStyles'

const ProductCard = ({ image, lg, name, description, price, id, handleClick, currency, isSelected = false }) => {
  return (
    <>
      {
        !lg ? (
          <ProductCardStyles isSelected={isSelected} onClick={() => handleClick ? handleClick(id) : {}}>
            <div className="card__cover">
              {
                image ? (
                  <div>
                    <img src={image} alt={name} />
                  </div>

                ) : (
                  <div>
                    <img src={lazy_loader} alt={name} />
                  </div>
                )
              }


            </div>
            <div className="card__content">
              <h3><SliceText number={20} text={name} /></h3>
              <p><SliceText number={35} text={description} isDescription /></p>

              <br />

              {price && (<><label>Price</label>
                <h2>{currency ? toCurrency(price, currency) : price}</h2></>)}
            </div>
          </ProductCardStyles>
        ) : (
          <ProductCardLGStyles isSelected={isSelected} onClick={() => handleClick ? handleClick(id) : {}}>
            <div className="card__cover">
              {
                image ? (
                  <div>
                    <img src={image} alt={name} />
                  </div>

                ) : (
                  <div>
                    <img src={lazy_loader} alt={name} />
                  </div>
                )
              }


            </div>
            <div className="card__content">
              <h3><SliceText number={20} text={name} /></h3>
              <p><SliceText number={40} text={description} isDescription /></p>

              <br />

              {price && (<><label>Price</label>
                <h2>{currency ? toCurrency(price, currency) : price}</h2></>)}
            </div>

          </ProductCardLGStyles>
        )
      }
    </>

  )
}

export default ProductCard