import React from 'react'
import { empty_assets } from '../../../assets/images'

const EmptyTableState = ({description}) => {
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} >
        <img src={empty_assets} height="80px" width="80px" />
        <span>{description}</span>
    </div>
  )
}

export default EmptyTableState