import { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { useAsyncDebounce } from 'react-table'
import CustomInput from '../CustomInput/CustomInput'

const TableSearch = ({ filter, setFilter, placeholder }) => {
    const [value, setValue] = useState(filter);

    const onChange = useAsyncDebounce(value => {
      setFilter(value || undefined)
    }, 1000)

    return (
        <CustomInput
            placeholder={placeholder}
            Icon= {BiSearch}
            type="text"
            name={"search"}
            value={value || ''}
            handleChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
        />
      
    )
  }

  export default TableSearch;