import styled from 'styled-components';

export const OnboardingStyles = styled.section`
  padding: 0 3rem;
  margin-top: 64px;
  height: 740px;

  @media (min-width: 1281px) {
    padding: 0 6rem;
  }

  @media screen and (max-width: 912px) {
    padding: 0 2rem;
    height: auto;
  }

  @media screen and (max-width: 550px) {
    padding: 0 1rem;
  }
`;
