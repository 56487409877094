import React from 'react'
import { empty_instance } from '../../../assets/images'
import Button from '../Button'
import { EmptyInstanceStyle } from './EmptyInstanceStyle'

const EmptyInstance = ({ handleClick, text_only, insertText, title, sub_title, ctaText, pointToSales }) => {
  return (
    <EmptyInstanceStyle>
      {
        !text_only ? (
          <>
            <div className='empty_instance_img'>
              <img src={empty_instance} alt="empty_instance_img" />
            </div>
            <h4 className='no_asset_h1'>No assets found</h4>
            <p className='no_asset_p'>Your search {insertText && `"${insertText}"`} did not match any products. {pointToSales ? 'Comtact our sales team to place your request directly' : 'Please try again.'}</p>
            <br />
            <div className='empty_instance_btn'>
              <Button has_blackborder handleClick={handleClick}>
                {ctaText ?? 'Clear search'}
              </Button>

              {pointToSales &&
                (<a href='mailto:hello@rayda.co'>
                <Button bg_blue handleClick={handleClick}>
                  
                  {'Contact Sales'}
                  
                </Button>
                </a>)}
            </div>
          </>
        ) : (
          <>
            <h4 className='no_asset_h1'>{title}</h4>
            <p className='no_asset_p'>{sub_title}</p>
          </>
        )
      }


    </EmptyInstanceStyle>
  )
}

export default EmptyInstance