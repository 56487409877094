import styled from 'styled-components';

export const PartneringProgressStyles = styled.section`
  height: 688px;
  display: flex;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  background: ${(props) => props.theme.colors.primary_1000};
  padding: 0 3rem;

  .h1_text {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: ${(props) => props.theme.colors.neutral_10};
  }

  .p_text {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.colors.neutral_10};
    width: 576px;
    margin-top: 20px;
  }

  .content_btn {
    margin-top: 30px;
    width: 153px;
  }

  @media (min-width: 1281px) {
    padding: 0 6rem;
  }

  @media screen and (max-width: 912px) {
    padding: 2rem 2rem 6rem 2rem;
    height: auto;

    .p_text {
      width: 100%;
    }
  }

  @media screen and (max-width: 550px) {
    padding: 2rem 1rem 6rem 1rem;
  }
`;
