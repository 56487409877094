import styled from 'styled-components';

export const CustomErrorpageStyles = styled.article`
  width: 100%;
  height: 67vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0 3rem;

  .section_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto 0;
  }

  .aside_text_content,
  .error_aside_image_container {
    flex: 1;
  }

  .aside_text_content {
    .title_text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #003999;
      margin-bottom: 32px;
    }

    .main_title_text {
      font-weight: 600;
      font-size: 60px;
      line-height: 72px;
      letter-spacing: -0.02em;
      color: #101828;
    }

    .message_text {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #475467;
      width: 480px;
      height: 90px;
      margin: 32px 0;
    }

    .btn_container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 50%;
      align-items: center;

      .btn_goback {
        width: 128px;
        height: 48px;
      }

      .btn_contact {
        width: 162px;
        height: 48px;
      }
    }
  }

  .error_aside_image_container > .image_container {
    img {
      width: 100%;
      object-fit: fill;
    }
  }

  @media (min-width: 1281px) {
    padding: 0 0 0 6rem;
    height: 80vmax;
  }

  @media screen and (max-width: 912px) {
    height: 100vmax;
    padding: 0 0 0 2rem;

    .aside_text_content > .btn_container {
      width: 65%;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 0 0 3rem;
  }

  @media screen and (max-width: 550px) {
    padding: 2rem;
    height: auto;

    .aside_text_content,
    .error_aside_image_container {
      width: 100%;
    }

    .aside_text_content > .main_title_text {
      font-weight: 600;
      font-size: 50px;
      line-height: 72px;
      letter-spacing: 3px;
    }

    .aside_text_content > .message_text {
      width: 100%;
      height: auto;
    }

    .aside_text_content > .btn_container {
      width: 100%;
      flex-direction: column;
      margin-top: 64px;
    }

    .aside_text_content > .btn_container > .btn_goback,
    .aside_text_content > .btn_container > .btn_contact {
      width: 100%;
      margin-top: 20px;
    }

    .section_container {
      flex-direction: column;

      .error_aside_image_container {
        margin-top: 52px;
      }
    }
  }

  @media (max-width: 320px) {
    padding: 1rem;

    .aside_text_content > .btn_container {
      margin-top: 32px;
    }
  }
`;
