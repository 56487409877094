import {useCallback, useEffect, useRef } from 'react';
import Globe from 'react-globe.gl';

const CustomGlobe = () => {
  const globeRef = useRef();

  useEffect(() => {
    globeRef.current.controls().autoRotate = true;
    globeRef.current.controls().enableZoom = false;
  }, []);

  const splitter = array => {
    const arr = [];

    array.forEach(item => {
      arr.push({
        lat: item.startLat,
        lng: item.startLng
      });
      arr.push({
        lat: item.endLat,
        lng: item.endLng
      });
    });

    return arr;
  };
  return (
    <Globe
      globeImageUrl={"//res.cloudinary.com/db37j4moo/image/upload/v1675090508/Map_ale5my.svg"}
      arcColor={'color'}
      arcsData={arcsData}
      arcDashLength={useCallback(() => 2)}
      arcDashGap={useCallback(() => 2)}
      arcDashAnimateTime={useCallback(() => Math.random() * 10000)}
      ringColor={useCallback(() => t => `rgba(255,255,255,${1 - t})`)}
      ringsData={splitter(arcsData)}
      arcStroke={() => 0.4}
      backgroundColor={'rgba(0,0,0,0)'}
      width={750}
      height={750}
      ref={globeRef}
    />

    
  )
}

const arcsData = [
  {
    startLat: 34.0522,
    startLng: -118.2437,
    endLat: 48.8566,
    endLng: 2.3522,
    color: '#ffffff'
  },
  {
    startLat: 37.7749,
    startLng: -122.4194,
    endLat: -14.2350,
    endLng: -51.9253,
    color: '#ffffff'
  },
  {
    startLat: 40.7128,
    startLng: -74.0060,
    endLat: 47.6062,
    endLng: -122.3321,
    color: '#ffffff'
  },
  {
    startLat: 55.3781,
    startLng: -3.4360,
    endLat: 27.6648,
    endLng: -81.5158,
    color: '#ffffff'
  },
  {
    startLat: 20.5937,
    startLng: 78.9629,
    endLat: 52.1326,
    endLng: 5.2913,
    color: '#ffffff'
  },
  {
    startLat: -30.5595,
    startLng: 22.9375,
    endLat: 56.1304,
    endLng: -106.3468,
    color: '#ffffff'
  },
  {
    startLat: 23.4241,
    startLng: 53.8478,
    endLat: -25.2744,
    endLng: 133.7751,
    color: '#ffffff'
  },
  {
    startLat: 1.3521,
    startLng: 103.8198,
    endLat: 41.8719,
    endLng: 12.5674,
    color: '#ffffff'
  },
  {
    startLat: 40.4637,
    startLng: -3.7492,
    endLat: 34.0522,
    endLng: -118.2437,
    color: '#ffffff'
  }
];

export default CustomGlobe;