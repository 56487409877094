import { SET_STAGE } from '../types';

const initialState = {
  stage: ''
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STAGE:
      return {
        ...state,
        stage: payload
      };
    default:
      return state;
  }
};
