import styled from 'styled-components';

const CartModalStyle = styled.article`
  padding: 1rem 0;

  .order_items_area {
    padding: 0 1rem;
    position: sticky;
    overflow-y: scroll;
    height: 20vh;
  }

  .cost_area {
    height: auto;
    ${'' /* height: 120px; */}
    padding: 1rem;
  }

  .list_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    margin-top: 12px;

    h3 {
      font-weight: 600;
      color: #101828;
    }
    h3,
    p {
      font-size: 16px;
      line-height: 24px;
    }

    p {
      font-weight: 400;
      color: #475467;
    }

    .total {
      color: #003999;
    }
  }

  .description_area {
    height: 130px;
    background: #f9fafb;
    padding: 1rem 1rem;

    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin-top: 12px;
    }
  }

  .order_items_footer {
    padding: 1rem 1rem;
  }

  @media (min-width: 1281px) {
    .order_items_area {
      height: 30vh;
    }
  }

  @media (max-width: 280px) {
    .order_items_area {
      padding: 0 0.4rem;
    }

    .cost_area {
      padding: 1 0.4rem;
    }

    .description_area {
      height: auto;
    }
  }
`;
export default CartModalStyle;
