import ReactDom from 'react-dom';
import { your_cart_icon } from '../../../assets/icons';
import '../../../index.css';

const CustomPortalModal = ({ open, children, hasHeader_image, headerText_title, headerText_subtitle, setOpen }) => {
    
    if (!open) return null;
    
    return ReactDom.createPortal(
        <>
            <div className="portal_modal_overlay" onClick={() => setOpen((prev) => !prev)} />

            <div className="portal_modal_content">
                <div className="portal_modal_header">
                    {
                        hasHeader_image && (
                            <div className='image_container'>
                                <div>
                                    <img src={your_cart_icon} alt="your_cart_icon" />
                                </div>
                                
                            </div>
                        )
                    }
                    
                    <div className={hasHeader_image ? `header_content` : `header_content_without_image`}>
                        <h1>{headerText_title}</h1>
                        {
                            headerText_subtitle && (
                                <p>{headerText_subtitle}</p>
                            )
                        }
                        
                    </div>
                    <div className='header_close_area'>
                        <div className="close" onClick={() => setOpen((prev) => !prev)}>
                            &times;
                        </div>
                    </div>
                    
                </div>
                <main className='portal_modal_main_content'>
                    {
                        children
                    }
                </main>
            </div>
        </>,
        document.getElementById('custom_portal_modal')
    )
}

export default CustomPortalModal