import styled from 'styled-components';

export const EditUserModelStyle = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding-bottom: 16px;
  border-radius: 0 0 12px 12px;

  .form_container {
    padding: 0 24px 24px 24px;
  }

  .header_text {
    padding: 12px 24px;
  }

  .header_text > hr {
    color: #eaecf0;
    border: 1px solid #eaecf0;
    margin: 20px 0;
  }

  .header_text > h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
  }

  .header_text > p {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    margin-top: 12px;
  }

  .form_field_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .form_field_container > .aside_left,
  .form_field_container > .aside_right {
    width: 49%;
  }

  .meta {
    margin-top: 3rem;
  }

  .ce_btn_container,
  .btn_container {
    display: flex;
    flex-direction: row;
    ${'' /* justify-content: flex-end; */}

    .btn {
      width: 250px;
      height: 44px;
    }
  }

  .btn_container {
    justify-content: space-between;
    width: 100%;
  }
`;
