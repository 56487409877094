import styled from 'styled-components';

export const InitialsAvatarStyle = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  background: #f2f4f7;
  cursor: pointer;

  .avatar__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #475467;
  }
`;
