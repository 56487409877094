import React, { useState } from 'react';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import { AccordionStyles } from './accordionStyles';

const Accordion = ({ title, info }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <AccordionStyles>
      <header onClick={() => setShowInfo(!showInfo)} className="accordion_header">
        <h4>{title}</h4>
        <button className="btn" onClick={() => setShowInfo(!showInfo)}>
          {showInfo ? <BiMinusCircle size={25} /> : <BiPlusCircle size={25} />}
        </button>
      </header>
      {showInfo && <p className="accordion_body">{info}</p>}
    </AccordionStyles>
  );
};

export default Accordion;
