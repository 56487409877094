import styled from 'styled-components';

export const BreadcrumbStyle = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  svg {
    color: #475467;
  }

  span {
    margin-left: 0.3rem;
  }

  .breadcrumb_item {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0.6rem;
    color: #475467;
    position: relative;
  }

  .breadcrumb_item:not(:last-child)::after {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(0.8rem, 0px);

    content: ' >';
  }

  .breadcrumb_item:last-child {
    color: #003999;
  }

  .homeicon:hover {
    cursor: pointer;
    color: blue;
    transition-delay: 100ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
`;
