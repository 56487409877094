import React, { Fragment } from 'react';
import { LgTitle, MdTitle, SmTitle } from './TitleStyles';

const Title = ({ sm, md, lg, text, center, is_white }) => {
  return (
    <Fragment>
      {sm && (
        <SmTitle>
          <p className={!is_white ? 'default' : 'is_white'}>{text}</p>
        </SmTitle>
      )}
      {md && (
        <MdTitle>
          <h3 className={center ? 'md_center' : 'md_default'}>{text}</h3>
        </MdTitle>
      )}
      {lg && (
        <LgTitle>
          <h3 className={center ? 'lg_center' : 'lg_default'}>{text}</h3>
        </LgTitle>
      )}
    </Fragment>
  );
};

export default Title;
