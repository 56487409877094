import styled from 'styled-components';

export const DeliveryTypeStyle = styled.label`
  width: 100%;
  height: 72px;

  .input {
    display: none;
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 12px;
    width: inherit;

    .card_img {
      width: 32px;
    }

    .content_area {
      flex: 1;
      margin-left: 16px;

      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      .sub_title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .input_content-side {
      width: 16px;
    }
  }

  .rounded_content {
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
  }

  .check_box {
    width: 16px;
    height: 16px;
    display: none;
  }

  .input:hover + span,
  .input:checked + span {
    background: #f2f7ff;
    border: 2px solid #004ccc;
    border-radius: 12px;
  }

  .input:hover + span > .rounded_content {
    background: #004ccc;
  }
  .input:checked + span > .rounded_content {
    display: none;
  }

  .input:checked + span > .check_box {
    display: block;
  }

  .input:hover + span > .content_area > .title,
  .input:checked + span > .content_container > .content_area > .title {
    color: #002666;
  }

  .input:hover + span > .content_area > .sub_title,
  .input:checked + span > .content_area > .sub_title {
    color: #003999;
  }
`;
