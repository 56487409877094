import styled from 'styled-components';

export const OffBoardingStyles = styled.section`
  padding: 0 3rem;
  ${'' /* margin-top: 15px; */}
  height: 740px;

  @media (min-width: 1281px) {
    padding: 0 6rem;
  }

  @media screen and (max-width: 912px) {
    height: auto;
    padding: 0 2rem 6rem 2rem;
  }

  @media screen and (max-width: 550px) {
    padding: 0 1rem 3rem 1rem;
    margin-top: 0.4rem;
  }
`;
