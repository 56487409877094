import React from 'react';
import { BenefitCardStyles, SmBenefitStyle } from './benefitCardStyles';

const BenefitCard = (props) => {
  const { image, title, children, sm, key, sm_body_text } = props;
  return sm ? (
    <SmBenefitStyle key={key}>
      <img src={image} alt={`${image}`} />
      <header>{title}</header>
      <p>{sm_body_text}</p>
      <small>{children}</small>
    </SmBenefitStyle>
  ) : (
    <BenefitCardStyles key={key}>
      <img src={image} alt={`${image}`} />
      <header>{title}</header>
      <main className="benefit_text">
        <p>{children}</p>
      </main>
    </BenefitCardStyles>
  );
};

export default BenefitCard;
