import styled from 'styled-components';

export const ContactUsStyles = styled.section`
  width: 100%;
  height: 652px;
  padding: 6rem 3rem;
  background: ${(props) => props.theme.colors.primary_1000};

  .meta {
    margin-top: 12px;
  }

  .h1_text {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: ${(props) => props.theme.colors.neutral_10};
  }

  .p_text {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.colors.neutral_10};
  }

  .contact_us_cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: 1281px) {
    padding: 6rem;
  }

  @media screen and (max-width: 912px) {
    height: auto;
    padding: 4rem 2rem;

    .contact_us_cards {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 550px) {
    padding: 4rem 1rem;
  }
`;
