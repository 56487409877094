import React, { useContext } from 'react';
import ReactDom from 'react-dom';
// import { FiSettings } from 'react-icons/fi';
import { HiX } from 'react-icons/hi';
import { NavLink, useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import '../../../index.css';
import { AppContext } from '../../../utils';
import { Button, InitialsAvatar } from '../../shared';
import { portalHeaderData } from '../../shared/Header/headerData';

const MobileNav = ({ open, headerData, portal, handleOpen, initials }) => {
  const { setOpenNav } = useContext(AppContext);
  const navigate = useNavigate();

  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div className="navMobile_overlay" onClick={() => setOpenNav((prev) => !prev)} />
      <nav className="navbarLinksMobileContainer">
        <div>
          <HiX size={27} onClick={() => setOpenNav((prev) => !prev)} />
        </div>
        <ul className="app_navbar-link-mobile">
          {headerData &&
            !portal &&
            headerData.map(({ id, name, path }, index) => (
              <li key={id}>
                <Link
                  className="nav_link"
                  activeClass="activeClassName"
                  to={path}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  key={index}
                  onClick={() => setOpenNav((prev) => !prev)}
                >
                  {name}
                </Link>
                {/* <NavLink
                            className={({ isActive }) =>
                            isActive ? "activeClassName" : "nav_link"}
                            to={path} 
                            onClick={() => setOpenNav((prev) => !prev)}
                            >
                                {name}
                        </NavLink> */}
              </li>
            ))}
          {portalHeaderData &&
            portal &&
            portalHeaderData.map(({ id, name, path }) => (
              <li key={id} onClick={() => setOpenNav((prev) => !prev)}>
                <NavLink className="nav_link_portal" to={path}>
                  {name}
                </NavLink>
              </li>
            ))}
        </ul>

        {!portal && (
          <div className="nav_btn_container">
            {/* <Button bg_default={true} handleClick={() => navigate('/login')}>
                Login
              </Button>
              <div className="meta" /> */}
            <Button bg_blue={true} handleClick={() => navigate('/register')}>
              Get started
            </Button>
          </div>
        )}

        {portal && (
          <div className="nav_btn_container_portal">
            {/* <div>
              <FiSettings />
            </div> */}
            <InitialsAvatar initials={initials} />
          </div>
        )}

        {/* <Button withBorderColor={true} handleClick={handleOpen} >Request Early Access</Button> */}
      </nav>
    </>,
    document.getElementById('mobile_nav')
  );
};

export default MobileNav;
