import styled from 'styled-components';

export const CountriesStyles = styled.section`
  padding: 6rem 3rem;
  width: 100%;

  .content_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }

  .article_area_container {
    width: 50%;
  }

  .animated_wrapper {
    width: 45%;
    overflow: hidden;
    height: 322px;
    background: linear-gradient(
      91deg,
      rgba(255, 255, 255, 0) 0.81%,
      rgba(255, 255, 255, 0.453125) 41.25%,
      #ffffff 99.1%
    );
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .animated_area_container {
    width: inherit;
    border: none;
    display: flex;
    flex-direction: column;
  }

  .animated_area_left,
  .animated_area_right {
    width: inherit;
    box-sizing: border-box;
    position: relative;
  }

  .animated_area_left > .inner_container {
    display: block;
    position: absolute;
    animation: animated_area_left 10s linear infinite;
  }

  .animated_area_right > .inner_container {
    display: block;
    position: absolute;
    animation: animated_area_right 10s linear infinite;
  }

  .animated_area_left > .inner_container:hover {
    animation-play-state: paused;
  }

  .animated_area_right > .inner_container:hover {
    animation-play-state: paused;
  }

  .animated_area_left > .inner_container > .inner_content,
  .animated_area_left > .inner_container > .inner_content > span,
  .animated_area_right > .inner_container > .inner_content,
  .animated_area_right > .inner_container > .inner_content > span {
    display: flex;
    align-items: center;
  }

  .animated_image {
    width: 96px;
    margin: 0 24.7px;
    transition: all 0.2s ease-out;
    display: inline-block;

    img {
      width: 100%;
      object-fit: fill;
    }
  }

  @keyframes animated_area_left {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }

  @keyframes animated_area_right {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }

  .animated_area_right {
    margin-top: 8rem;
  }

  .tspc_h_Text {
    ont-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #101828;
    width: 100%;
  }

  .meta {
    margin-top: 12px;
  }

  .tspc_pText {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    width: 560px;
    color: ${(props) => props.theme.colors.neutral_750};
  }

  .content_btn {
    margin-top: 3rem;
    width: 153px;
  }

  @media (min-width: 1281px) {
    padding: 6rem;
  }

  @media screen and (max-width: 912px) {
    padding: 4rem 2rem;

    .content_wrapper {
      flex-direction: column;
    }

    .article_area_container,
    .animated_wrapper {
      width: 100%;
    }

    .animated_wrapper {
      margin-top: 6rem;
    }

    .animated_area_right {
      margin-top: 10rem;
    }

    @keyframes animated_area_right {
      0% {
        right: 0;
      }
      100% {
        right: -100%;
      }
    }
  }

  @media screen and (max-width: 550px) {
    padding: 0 1rem;
  }
`;
