import styled from 'styled-components';

const ArticleTitleStyles = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.secondary_500};
`;

export { ArticleTitleStyles };
