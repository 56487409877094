import { lazy_loader } from "../../../assets/images"
import SliceText from "../../../utils/helpers/SliceText"
import { CartItemCardStyle } from "./CartItemCardStyle"
import { AiOutlineDelete } from "react-icons/ai";
import StepperInput from "../StepperInput/StepperInput";
import { useEffect, useState } from "react";
import toCurrency from "../../../utils/helpers/toCurrency";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { deleteCartAction } from "../../../stateManagement/reactQuery/actions/portalActions";


const CartItemCard = ({image, asset_id, quantity, asset_name, price, currency, cart_id, company_id}) => {
    const queryClient = useQueryClient();

    const [formValue, setFormValue] = useState({
        count: quantity,
    });
    

    useEffect(() => {
        setFormValue({
            count: quantity
        })
    }, [quantity]);

    useEffect(() => {
        if(formValue.count < 1){
            setFormValue({
                count: Number(1)
            })
        }
    }, [formValue.count]);

    const handleChange = (e) => {
        const getValue = {...formValue}
        getValue[e.target.name] = e.target.value
        setFormValue(getValue)
    }

    const {mutateAsync: handleRemoveFromCart } = useMutation((variables) => deleteCartAction(variables),
        {
            onSuccess: (response) => {
                const { data : {status} } = response;
                

                if(status === "success"){
                    toast.success(`Item successfully removed`);
                    queryClient.invalidateQueries('cart_items');
                }
            },

            onError: (error) => {
                toast.error(`Something went wrong: ${error.message}`)
            }
                
        }
    );

    const handle_removeFromCart = async () => {
        const formData = {
            asset_id: Number(asset_id)
        }
        try {
            await handleRemoveFromCart({company_id, cart_id, formData});
        
        } catch (error) {

        } 
    }

    


  return (
    <CartItemCardStyle>
        <div className='image_area'>
            <div className="image_container">
                <img src={!image ? lazy_loader : image } alt="cart_item_img" />
            </div>
            
        </div>
                    
        <div className='header_content'>
            {
                asset_name && (
                    <h1>
                        <SliceText
                            number={15}
                            text={asset_name}
                        />
                    </h1>
                )
            }
            

            <div className="meta"/>

            <StepperInput
                sm
                name={"count"}
                value={formValue.count}
                handleChange={handleChange}
                initialState={formValue}
                setState={setFormValue}
                asset_id={"internal-" + asset_id}
                currency={currency}
                cart_id={cart_id}
                company_id={company_id}
            />

        </div>

        <div className='header_action_area'>
            <AiOutlineDelete onClick={handle_removeFromCart}/>

            <div className="meta"/>

            <p>{currency ? toCurrency(price, currency) : price}</p>
        </div>
                    
    </CartItemCardStyle>
  )
}

export default CartItemCard