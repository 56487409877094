import React, { useContext, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { AppContext, gotoRoute } from '../../../utils';
import { country_flags } from '../../../utils/Data/countries';
import { Button } from '../../shared';
import CustomInput from '../../shared/CustomInput/CustomInput';
import CustomModal from '../../shared/CustomModal/CustomModal';
import { CountryModalStyle } from './CountryModalStyle';

const CountryModal = () => {
  const { show, setShow } = useContext(AppContext);
  const [searchCountry, setSearchCountry] = useState({
      search: ""
  })

  const handleSearchCountry = (e) => {
    const getValue = { ...searchCountry };
    const {name, value} = e.target;
    getValue[name] = value;

    setSearchCountry(getValue);
  }

  const {search} = searchCountry;

  return (
    <CustomModal open={show} setClose={setShow} hasHeader headerText={"Rayda Remote is currently available in"}>
      <CountryModalStyle>
                      
        <section className='countryCards_container'>
          {
            country_flags && country_flags.filter((val) =>  val?.name.toLowerCase().includes(search?.toLowerCase())).map(({name, flag}, index) => (
              <div key={index} className='countryCards'>
                <div className='countryCards_img'>
                  <img src={flag} alt={`${flag}_icon`} />
                </div>
                <p className='countryCards_text'>{name}</p>
            </div>
            ))
       
          }
        </section>
        <section className='country_banner'>
          <h3>New countries launching everyday. Check back soon for updates.</h3>
        </section>
        <section className='country_footer'>
          
          <div className='items_container'>
            <div className='search'>
              <CustomInput
                placeholder={"Search"}
                Icon= {BiSearch}
                type="text"
                name={"search"}
                value={search}
                handleChange={handleSearchCountry}
              />
            </div>
            <div className="content_btn">
              <Button bg_blue={true} handleClick={() => gotoRoute("https://calendly.com/abisoye-fadare/partner-onboarding-brief")}>
                Book a demo
              </Button>
            </div>
          </div>
          
        </section>

      </CountryModalStyle>


    </CustomModal>
  )
}

export default CountryModal