import React from 'react';
import { OrderStatusStyles } from './OrderStatusStyles';
import { dayandmonth } from '../../../utils/helpers/dayAndMonth';

const transformStatus = (status) => {
  const words = status?.replace(/_/g, ' ').split(' ');
  return words
    ?.map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

const OrderStatus = ({
  image_1,
  image_2,
  title,
  transaction_successful,
  body_text,
  date,
  waiting,
  normal,
  picked_up,
  in_transit,
  verify_payment,
  awaiting_delivery,
  transaction_failed,
  status
}) => {
  return (
    <OrderStatusStyles>
      <div
        className={
          transaction_failed
            ? 'status_card failed'
            : waiting || verify_payment
            ? 'status_card waiting'
            : normal || awaiting_delivery
            ? 'status_card normal'
            : picked_up || transaction_successful
            ? 'status_card picked_up'
            : in_transit
            ? 'status_card in_transit'
            : ''
        }
      >
        <header
          className={
            transaction_failed
              ? 'header failed_header'
              : waiting || verify_payment
              ? ' header waiting_header'
              : normal || awaiting_delivery
              ? 'header normal_header'
              : picked_up || transaction_successful
              ? 'header picked_up_header'
              : in_transit
              ? 'header in_transit_header'
              : ''
          }
        >
          <img className="image_1" src={image_1} alt="" />
          <p>{title}</p>
          {/* <img src={image_2} alt=""/> */}
        </header>
        <main
          className={
            transaction_failed
              ? 'main failed_main'
              : waiting || verify_payment
              ? ' main waiting_main'
              : normal || awaiting_delivery
              ? 'main normal_main'
              : picked_up || transaction_successful
              ? 'main picked_up_main'
              : in_transit
              ? 'main in_transit_main'
              : ''
          }
        >
          <div className="bodytext_container">
            <h3>{body_text}</h3>
            {date && <p>{dayandmonth(date)}</p>}
          </div>
          {transaction_failed && <p className="failed_badge">{transformStatus(status)}</p>}
          {verify_payment && <p className="waiting_badge">{transformStatus(status)}</p>}
          {waiting && <p className="waiting_badge">{transformStatus(status)}</p>}

          {awaiting_delivery && <p className="waiting_badge">{transformStatus(status)}</p>}
          {picked_up && <p className="picked_up_badge">{transformStatus(status)}</p>}
          {in_transit && <p className="in_transit_badge">{transformStatus(status)}</p>}
          {transaction_successful && <p className="picked_up_badge">{transformStatus(status)}</p>}
        </main>
      </div>
    </OrderStatusStyles>
  );
};

export default OrderStatus;
