import { Fragment } from "react";
// import ReactGA from 'react-ga';
import { Layout } from "./Layout";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function App() {

  // useEffect(() => {
  //   ReactGA.initialize('G-K5X9V9MQ2P');
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, [])
  
  return (
    <Fragment>
      <Layout/>
    </Fragment>
  );
}

export default App;
