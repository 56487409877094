import styled from 'styled-components';

export const TablePaginationStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .previous_container,
  .page_container,
  .next_main_container {
    display: flex;
    align-items: center;
  }

  .next_container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .previous_container,
  .next_container {
    width: 150px;
  }

  .page_container {
    flex: 1;
  }

  .previous_sm,
  .next_sm {
    width: 50px;
    height: 36px;
  }

  .previous_sm,
  .next_lg {
    margin-right: 10px;
  }

  .previous_lg,
  .next_lg {
    width: 113px;
    height: 36px;
  }

  .page_container {
    justify-content: space-between;

    p {
      display: block;
      margin-right: 10px;
    }

    .page_input_container {
      display: flex;
      align-items: center;
      margin: 0 auto;
    }

    .page_input {
      width: 50px;
    }
  }

  @media screen and (max-width: 481px) {
    .previous_lg,
    .next_lg {
      width: 90px;
      height: 36px;
    }
  }

  @media (max-width: 280px) {
    .previous_lg,
    .next_lg {
      width: 70px;
      height: 36px;
    }
  }
`;
