import { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi'
import { delete_icon } from '../../../assets/icons';
import { Breadcrumb, Button, CheckboxDropdown, CustomCheckbox, CustomInput, CustomLoader, CustomSelect, EmptyInstance, Grid, ProductCard } from '../../../components/shared'
import { MainstoreStyles } from './MainstoreStyles';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../../../services/helper';
import { avaliableProductAction, fetchAssetCategories, getAllProductDataAction } from '../../../stateManagement/reactQuery/actions/portalActions';
import { useMutation, useQuery } from 'react-query';
import { Slider } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { productsAction } from '../../../stateManagement/redux/actions/productsAction';


const MainStore = () => {
    const { company_id, currency } = JSON.parse(getUserData());
    const productsData = useSelector(({ products }) => products?.products?.products);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let randomString = Math.random().toString(36);

    // const [retriveData, setRetrieveData] = useState([])
    // const [spinner, setSpinner] = useState(false);
    const [filter_isLoading, setFilter_isLoading] = useState(false);
    const [clear_isLoading, setClear_isLoading] = useState(false);
    const [isDisAbled, setIsDisAbled] = useState(true);

    const [categoryKey, setCategoryKey] = useState();
    const [assetStateKey, setAssetStateKey] = useState();
    const [availablityTypeKey, setAvailablityTypeKey] = useState();

    const [sortby, setSortBy] = useState("");
    const [value, setValue] =  useState([]);

    const {data: all_assetCategories_data } = useQuery(['all_assetCategories', company_id], 
        fetchAssetCategories,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    );

    const {data: all_products_data, isLoading: all_products_isLoading} = useQuery(['all_products', company_id], 
        getAllProductDataAction,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    );

    

    useEffect(() => {
        if(all_products_data?.data?.data){
            dispatch(productsAction(all_products_data?.data?.data))
            // setRetrieveData(all_products_data?.data?.data);
        }
        
    }, [all_products_data?.data?.data])

    const {mutateAsync: product_data } = useMutation((variables) => avaliableProductAction(variables),
		{
			onSuccess: (response) => {
				const { data : { data} } = response;
                dispatch(productsAction(data))
                // setRetrieveData(data);
                // setSpinner(false);
			},

            onError: (error) => {
                toast.error(`Something went wrong: ${error?.message}`)
            },

            retry: false
            
		}
    );

    // useEffect(() => {
    //     (async () => {
            // try {
            //     setSpinner(true);
        
            //     await product_data({company_id});
        
            //    } catch (error) {
            //       setSpinner(false)
            //    }finally {
            //     setSpinner(false)
            // }
            
    //     })();
    // }, []);

    // useEffect(() => setSpinner(true), []);
    
    const [searchItem, setSearchItem] = useState({
        search: ""
    })

    const [formValue, setFormValue] = useState({
        type: new Set(),
        asset_state: new Set(),
        availability_Type: new Set()
      });


    const [rangeInput, setRangeInput] = useState({
        from: "",
        to: "",
    })

    const setCurrency = () => {
        // || currency.toLowerCase() === 'eur'
        // || currency.toLowerCase() === 'gbp'

        if(currency?.toLowerCase() === 'ngn' ){
            setRangeInput(
                {
                    from: 1000,
                    to: 10000000,
                }
            )
            setValue([1000, 10000000]);
        } else {
            setRangeInput(
                {
                    from: 100,
                    to: 1000,
                }
            )
            setValue([100, 1000]);
        }

    }

    useEffect(() => {
        setCurrency();

    }, [currency])

    const {from, to} = rangeInput;

    
  
    const rangeSelector = (event, newValue) => {

        setValue(newValue);
        setRangeInput({
            from: newValue[0],
            to: newValue[1]
        })
    };




    const handleRangeInputValue = (e) => {
        const getValue = { ...rangeInput };
        const {name, value} = e?.target;
        getValue[name] = value;

        const {from, to} = getValue;

        setRangeInput(getValue)

        setValue([from, to])

        setSearchItem(getValue);
    }

    const handleSearchValue = (e) => {
        const getValue = { ...searchItem };
        const {name, value} = e?.target;
        getValue[name] = value;

        setSearchItem(getValue);
    }
    
    const {search} = searchItem;
    
    const handleChange = (e) => {
        let isChecked = e.target.checked;
        const getValue = {...formValue};

        if (isChecked) {
            getValue[e.target.name].add(e.target.value)
        } else {
            const removeLast = Array.from(getValue[e.target.name]).pop();
            const newArr = Array.from(getValue[e.target.name]).filter((item) => item !== removeLast);
            getValue[e.target.name] = new Set(newArr);
        }
        
        setFormValue(getValue)
    }

    const handleClick = (id) => {
        navigate(`/portal/marketplace/details/${id}`)
    };

    const handleFilter =  async() => {
        try {
            setFilter_isLoading(true);
            let enableQuery;

            if(Array.from(formValue.type).join(',') && Array.from(formValue.availability_Type).join(',') && Array.from(formValue.asset_state).join(',')) {
                enableQuery = `category=${Array.from(formValue.type).join(',')}&type=${Array.from(formValue.availability_Type).join(',')}&from=${from}&to=${to}&state=${Array.from(formValue.asset_state).join(',')}`;
            }

            if(Array.from(formValue.type).join(',') && Array.from(formValue.availability_Type).join(',') && !Array.from(formValue.asset_state).join(',')) {
                enableQuery = `category=${Array.from(formValue.type).join(',')}&type=${Array.from(formValue.availability_Type).join(',')}&from=${from}&to=${to}`;
            }

            if(!Array.from(formValue.type).join(',') && Array.from(formValue.availability_Type).join(',') && Array.from(formValue.asset_state).join(',')) {
                enableQuery = `type=${Array.from(formValue.availability_Type).join(',')}&from=${from}&to=${to}&state=${Array.from(formValue.asset_state).join(',')}`;
            }

            if(Array.from(formValue.type).join(',') && !Array.from(formValue.availability_Type).join(',') && Array.from(formValue.asset_state).join(',')) {
                enableQuery = `category=${Array.from(formValue.type).join(',')}&from=${from}&to=${to}&state=${Array.from(formValue.asset_state).join(',')}`;
            }



            if(Array.from(formValue.type).join(',') && !Array.from(formValue.availability_Type).join(',') && !Array.from(formValue.asset_state).join(',')) {
                enableQuery = `category=${Array.from(formValue.type).join(',')}&from=${from}&to=${to}`;
            }

            if(!Array.from(formValue.type).join(',') && Array.from(formValue.availability_Type).join(',') && !Array.from(formValue.asset_state).join(',')) {
                enableQuery = `type=${Array.from(formValue.availability_Type).join(',')}&from=${from}&to=${to}`;
            }

            if(!Array.from(formValue.type).join(',') && !Array.from(formValue.availability_Type).join(',') && Array.from(formValue.asset_state).join(',')) {
                enableQuery = `from=${from}&to=${to}&state=${Array.from(formValue.asset_state).join(',')}`;
            }

            if(!Array.from(formValue.type).join(',') && !Array.from(formValue.availability_Type).join(',') && !Array.from(formValue.asset_state).join(',')) {
                enableQuery = `from=${from}&to=${to}}`;
            }

    
            await product_data({company_id, enableQuery});
    
           } catch (error) {
            setFilter_isLoading(false)
           }finally {
            setFilter_isLoading(false)
        }
    }


    const handleClearFilter = async () => {
        setCurrency();

        // setRangeInput({
        //     from: 1000,
        //     to: 1000000,
        // });
        // setValue([1000, 1000000]);

        setFormValue({
            type: new Set(),
            asset_state: new Set(),
            availability_Type: new Set()
        });

        setCategoryKey(randomString);
        setAssetStateKey(randomString);
        setAvailablityTypeKey(randomString);


        try {
            setClear_isLoading(true);
            await product_data({company_id});
    
           } catch (error) {
            setClear_isLoading(false)
           }finally {
            setClear_isLoading(false)
        }

        
    }

    const handleClearSearch = () => {
        setSearchItem({
            search: ""
        })
    }


    useEffect(() => {
        if(from !== "" &&
          to !== "" ||
          !Array.from(formValue.type).length < 1 ||
          !Array.from(formValue.availability_Type).length < 1 ||
          !Array.from(formValue.asset_state).length < 1 ) {
            setIsDisAbled(false)
          }else {
            setIsDisAbled(true)
            // await product_data({company_id});
        }
    
      }, [
        from,
        to,
        Array.from(formValue.type),
        Array.from(formValue.availability_Type),
        Array.from(formValue.asset_state)
      ])

      let filterValue;

      if(all_products_isLoading){
        return (
            <div style={{margin: "auto"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <CustomLoader/>
                </div>
            </div>
        )
      }

      
    
  return (
    <MainstoreStyles>
        <br/>
        <Breadcrumb data={[
            {
                pathname: "Overview",
                pathLink: "/portal/overview/dashboard",
            },
            {
                pathname: "Marketplace",
                pathLink: "",
            } 
            ]}
        />
        <br/>
        <div className="sidebar">
            <aside className="sidebar__sidebar">
                <h3 className='title_text'>Filter</h3>
                <p className='suTitle_text'>Find the perfect solution for your business needs</p>
                <br/>

                <hr/>

                <br/>
            
                <main className='filter_properties'>
                    <h3 className='title_text'>Price</h3>
                    <section className='range_amount'>

                    <div className='range_filed_left'>
                        <CustomInput
                        label={"From"}
                        name={"from"}
                        value={from}
                        // Icon={TbCurrencyNaira}
                        placeholder={`${from}`}
                        handleChange={handleRangeInputValue}
                        />
                    </div>
                    <div className='range_filed_right'>
                        <CustomInput
                            label={"To"}
                            name="to"
                            value={to}
                            // Icon={TbCurrencyNaira}
                            placeholder={`${to}`}
                            handleChange={handleRangeInputValue}
                        />
                    </div>
                    </section>
                    <section className='range_input'>
                        <Slider
                            getAriaLabel={() => 'Minimum distance shift'}
                            value={value}
                            onChange={rangeSelector}
                            valueLabelDisplay="auto"
                            min={currency?.toLowerCase() === 'ngn' ? 1000 : 100 }
                            max={currency?.toLowerCase() === 'ngn' ? 10000000 : 1000}
                        />
                        {/* <input className='range_field' type="range" min="1000" max="1000000" /> */}
                    </section>

                    <br/>
                    <CheckboxDropdown title={"Type"}>
                    {
                        !all_assetCategories_data?.data?.data ? (
                            <div style={{margin: "auto"}}>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <CustomLoader/>
                                </div>
                            </div>
                        ) : (
                            all_assetCategories_data?.data?.data?.map(({name}, index) => (
                                <CustomCheckbox handleChange={handleChange} id={"type"} refKey={categoryKey} name={"type"} value={name} key={index}>{name}</CustomCheckbox>
                            ))
                        )
                        
                    }
                    
                    </CheckboxDropdown>

                    <br/>
                    <CheckboxDropdown title={"Asset State"}>
                    {
                        [
                            {
                                title: "Brand New",
                                value: "new"
                            },
                            {
                                title: "Refurbished",
                                value: "refurbished"
                            },
                            {
                                title: "Used",
                                value: "used"
                            }].map(({title, value}, index) => (
                        <CustomCheckbox handleChange={handleChange} refKey={assetStateKey} id={"asset_state"} name={"asset_state"} value={value} key={index}>{title}</CustomCheckbox>
                        ))
                    }
                    
                    </CheckboxDropdown>

                    <br/>
                    <CheckboxDropdown title={"Availability Type"}>
                    {
                        [
                            {
                                title: "Sell",
                                value: "sale"
                            },
                            {
                                title: "Lease",
                                value: "lease"
                            }
                        ].map(({title, value}, index) => (
                            <CustomCheckbox handleChange={handleChange} refKey={availablityTypeKey} id={"availability_Type"} name={"availability_Type"} value={value} key={index}>{title}</CustomCheckbox>
                        ))
                    }
                    
                    </CheckboxDropdown>

                    <br/>
                    <div className='action_btn_container'>
                        {
                            !filter_isLoading ? (
                                <div className='action_btn_submit'>
                                    <Button handleClick={handleFilter} disabled={isDisAbled} bg_blue={true}>Apply Filter</Button>
                                </div>
                            ) : (
                                <div style={{margin: "auto"}}>
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                        <CustomLoader/>
                                    </div>
                                </div>
                            )
                        }
                        <div  className='action_container'>
                            {
                                !clear_isLoading ? (
                                    <button className='action_btn_delete' disabled={isDisAbled} onClick={handleClearFilter}>
                                        <img src={delete_icon} alt="delete_icon"/>
                                    </button>
                                ) : (
                                    <div style={{margin: "auto"}}>
                                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                            <CustomLoader/>
                                        </div>
                                    </div>
                                )
                            }
                            
                            
                        </div>
                    </div>
                </main>
            </aside>

        
            <main className="sidebar__main">
                <div className='items_container'>
                    <div className='search'>
                        <CustomInput
                            placeholder={"Search"}
                            Icon= {BiSearch}
                            type="text"
                            name={"search"}
                            value={search}
                            handleChange={handleSearchValue}
                        />
                    </div>
                    <div className="content_btn_container">
                        <div className="content_btn">
                            {/* <Button has_blackborder={true} >
                                <BsArrowDownUp/> Sort By
                            </Button> */}
                            <CustomSelect
                              optionData={['Price: High to low', 'Price: Low to High']}
                              isDefault
                              placeholder={"Select..."}
                              handleChange={async({ value }) => {
                                setSortBy(value)
                              }}
                            />
                        </div>
                    </div>
                    
                </div>
                
                <br/>
                <>
                <>
                    {
                        !productsData?.length < 1 ? (

                            <Grid>
                                {
                                    productsData?.filter((item) => {
                                        if(search !== "" && search){
                                            if(item?.asset_name?.toLowerCase().includes(search?.toLowerCase())) {
                                                filterValue = true;
                                                return (item?.asset_name?.toLowerCase().includes(search?.toLowerCase()))
                                            } else {
                                                filterValue = false;
                                                return null;
                                            }
                                            
                                        }
                                        
                                        return item
                                    }).sort((a, b) => {
                                    
                                        if(sortby === 'Price: High to low') {
                                            return parseFloat(b.price) - parseFloat(a.price)
                                        }

                                        if(sortby === 'Price: Low to High') {
                                            return parseFloat(a.price) - parseFloat(b.price)
                                        }

                                        return null
                                    }).map((item) => {
                                        if(item){
                                            const {asset_name, description, id, price, image, currency} = item;
                                            filterValue = true;
                                            return (
                                                <ProductCard key={id}
                                                    image={image}
                                                    id={id}
                                                    name={asset_name}
                                                    description={description}
                                                    price={price}
                                                    currency={currency}
                                                    handleClick={handleClick}
                                                />
                                            )
                                        } 

                                        return null;
                                    })
                                }
                            </Grid>
                        ) : (
                            <>
                                <EmptyInstance handleClick={handleClearFilter}/>
                            </>
                        )
                    }

                    {
                        filterValue !== undefined && filterValue === false &&  (
                            <>
                                <EmptyInstance pointToSales insertText={search} handleClick={handleClearSearch}/>
                            </>
                        )
                    }
                </>
        
                </>
            
            
            </main>
        </div>
    </MainstoreStyles>
    
  )
}

export default MainStore