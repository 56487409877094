import styled from 'styled-components';

export const SmTitle = styled.div`
  .is_white {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.basic_white};
  }

  .default {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.primary_700};
  }
`;

export const MdTitle = styled.div`
  font-family: 'IBM Plex Sans';
  font-style: normal;

  .md_center {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.colors.primary_950};
    text-align: center;
  }

  .md_default {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.colors.primary_950};
  }
`;
export const LgTitle = styled.div`
  font-family: 'IBM Plex Sans';
  font-style: normal;

  .lg_center {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: ${(props) => props.theme.colors.primary_950};
    text-align: center;
  }

  .lg_default {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: ${(props) => props.theme.colors.primary_950};
  }

  @media screen and (max-width: 820px) {
  }

  @media screen and (max-width: 550px) {
  }
`;
